import { useTranslation } from "react-i18next";

export default function TransitionTransform() {
    const { t } = useTranslation();

    return (
        <div className="w-full  bg-gradient-to-r from-primary-100 to-primary-200 p-6 flex justify-center">
            <div className="max-w-[1280px] uppercase text-white text-xl tracking-widest text-center">
                {t("studyCase.commun.transform")}
            </div>
        </div>
    )
}