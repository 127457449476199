import { useTranslation } from "react-i18next";
import topCorner from "../../assets/img/decorations/topRightCorner.png";
import bottomCorner from "../../assets/img/decorations/bottomRightCorner.png";

interface HeroProps {
    pictureLeft: string;
    nameForTranslation: string;
}

const Section = ({ titleKey, contentKey }: { titleKey: string; contentKey: string }) => {
    const { t } = useTranslation();
    return (
        <div className={`w-full ${titleKey !== "studyCase.commun.technologies" && "sm:w-1/3"} md:w-full flex flex-col gap-1`}>
            <h1 className="w-fit uppercase text-xl md:text-2xl text-[#355379] font-medium bg-white">{t(titleKey)}</h1>
            <div className="leading-5 text-xs md:text-[14px] 2xl:text-[16px]">{t(contentKey)}</div>
        </div>
    );
};

export default function Hero({ pictureLeft, nameForTranslation }: HeroProps) {
    const { t } = useTranslation();
    const sections = ["customer", "sector", "public", "expertise", "technologies"];

    return (
        <div className="w-full py-24 flex justify-center items-center">
            <div className="max-w-[1280px] flex flex-col md:flex-row gap-12 h-full items-center">
                <div className="w-full px-4 md:pl-12">
                    <img src={pictureLeft} alt="header" className="w-full" />
                </div>
                <div className="w-full flex flex-col gap-6 px-4 md:pr-12 relative">
                    <div className="relative z-10 w-fit text-left uppercase tracking-[0.2rem] text-primary-100 text-xs md:text-sm bg-white">
                        {useTranslation().t("studyCase.commun.preTitle")}
                    </div>
                    <div className="flex flex-col sm:flex-row flex-nowrap sm:flex-wrap gap-6 relative z-10 pr-12 sm:pr-16 md:pr-12 lg:pr-24">
                        {sections.map((section) => (
                            <Section key={section} titleKey={`studyCase.commun.${section}`} contentKey={`studyCase.${nameForTranslation}.${section}`} />
                        ))}
                    </div>
                    <div className="z-0 absolute right-4 md:right-12 top-0">
                        <img src={topCorner} alt="top corner" className="w-12 sm:w-16 md:w-12 lg:w-24 opacity-50" />
                    </div>
                    <div className="z-0 absolute right-4 md:right-12 bottom-0">
                        <img src={bottomCorner} alt="top corner" className="w-12 sm:w-16 md:w-12 lg:w-24 opacity-50" />
                    </div>
                </div>
            </div>
        </div>
    );
}
