interface HeaderProps {
    headerPicture: string;
    logoPicture: string;
}

export default function Header({ headerPicture, logoPicture }: HeaderProps) {
    return (
        <div className="w-full">
            <div className="w-full h-[350px] bg-cover bg-center bg-no-repeat relative rounded-b-xl bg-white">
                <div className="w-full h-full rounded-b-xl">
                    <img src={headerPicture} alt="header" className="w-full h-full object-cover rounded-b-[50px]" />
                </div>
                <div className="absolute -bottom-11 left-1/2 -translate-x-1/2 flex justify-center items-center bg-gradient-to-br from-primary-100 to-primary-200 w-fit p-1 rounded-lg">
                    <div className="w-full h-full bg-white p-2 rounded-md">
                        <img src={logoPicture} alt="logo" className="h-16" />
                    </div>
                </div>
            </div>
        </div>
    )
}