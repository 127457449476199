import Header from "../../components/StudyCase/Header";

import HeaderPicture from "../../assets/img/StudyCases/Ezoom/headerPicture.png"
import HeroPicture from "../../assets/img/StudyCases/Ezoom/heroPicture.png"
import LogoPicture from "../../assets/img/StudyCases/Ezoom/logo.png"
import CompanyPicture from "../../assets/img/StudyCases/Ezoom/companyPicture.png"
import Iteration1Picture from "../../assets/img/StudyCases/Ezoom/Iteration1Picture.png"
import Iteration2Picture from "../../assets/img/StudyCases/Ezoom/Iteration2Picture.png"
import Iteration3Picture from "../../assets/img/StudyCases/Ezoom/Iteration3Picture.png"

import Navbar from "../../components/Navbar/NavbarWithoutAnimation";
import Hero from "../../components/StudyCase/Hero";
import TransitionTransform from "../../components/StudyCase/TransitionTransform";
import DevelopmentCards from "../../components/StudyCase/DevelopmentCards";
import AmbitiousProject from "../../components/StudyCase/AmbitiousProject";
import Footer from "../../components/Footer/Footer";
import Challenge from "../../components/StudyCase/Challenge";
import Opinion from "../../components/StudyCase/Opinion";

export default function Ezoom() {
    return (
        <div>
            <Navbar />
            <Header 
                headerPicture={HeaderPicture}
                logoPicture={LogoPicture}
            />
            <Hero
                pictureLeft={HeroPicture}
                nameForTranslation="ezoom"
            />
            <TransitionTransform />
            <DevelopmentCards
                iteration={-1}
                nameForTranslation="ezoom"
                picture={CompanyPicture}
                contentSide="right"
                nbrPoints={0}
            />
            <Challenge
                nameForTranslation="ezoom"
            />
            <DevelopmentCards
                iteration={1}
                nameForTranslation="ezoom"
                picture={Iteration1Picture}
                contentSide="left"
                nbrPoints={3}
            />
            <DevelopmentCards
                iteration={2}
                nameForTranslation="ezoom"
                picture={Iteration2Picture}
                contentSide="right"
                nbrPoints={2}
            />
            <DevelopmentCards
                iteration={3}
                nameForTranslation="ezoom"
                picture={Iteration3Picture}
                contentSide="left"
                nbrPoints={3}
            />
            <Opinion
                nameForTranslation="ezoom"
            />
            <AmbitiousProject />
            <Footer />
        </div>
    )
}