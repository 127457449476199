import { InlineWidget } from "react-calendly";
import { useTranslation } from "react-i18next";

import "react-confirm-alert/src/react-confirm-alert.css";
import { Spinner } from "@material-tailwind/react";

export default function Meeting() {
    const { t } = useTranslation();

    return (
        <div className="flex gap-4">
            <div className="flex flex-col h-fit">
                <div className="px-4 text-base mb-2" dangerouslySetInnerHTML={{ __html: t("workTogether.meeting.description") }}></div>
                <InlineWidget
                    styles={{ height: "830px" }}
                    url="https://calendly.com/quentin-capelle/conseil?month=2024-05"
                    LoadingSpinner={() => <Spinner color="blue" />}
                />
            </div>
        </div>
    );
}
