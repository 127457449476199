import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Drawer, IconButton, Popover, PopoverContent, PopoverHandler, Typography } from "@material-tailwind/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function Navbar() {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [studyCaseOpen, setStudyCaseOpen] = useState(false);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const openDrawer = () => {
        if (open) return;
        setOpen(true);
    };

    const closeDrawer = () => {
        if (!open) return;
        setOpen(false);
    };

    return (
        <>
            <div
                className="w-full flex justify-center items-center fixed top-0 left-0 z-40 bg-gradient-to-r from-primary-100 to-primary-200 rounded-b-[24px] md:rounded-b-[54px] pb-1 px-1"
            >
                <div
                    className={`bg-white opacity-100 py-4 px-8 transition-all duration-500 ease-in-out flex gap-4 md:gap-0 md:justify-between items-center rounded-b-[25px] md:rounded-b-[50px]`}
                    style={{
                        width: "100%",
                    }}
                >
                    <div className="flex md:hidden">
                        <button onClick={openDrawer} disabled={open} className="text-primary-200 text-base cursor-pointer">
                            <FontAwesomeIcon icon="bars" />
                        </button>
                    </div>
                    <div className="text-primary-200 text-base md:text-2xl">
                        <a href="/" className="uppercase font-sans">
                            {t("brand.name")}
                        </a>
                    </div>
                    <div className="hidden md:flex flex-row gap-4 items-center">
                        {/* <a href="/#solution">
                            <div className="text-primary-200 hover:text-primary-200/50 transition-all text-base cursor-pointer">
                                <span className="hidden md:flex">{t("navbar.services")}</span>
                            </div>
                        </a> */}
                        {/* <a href="/#references">
                            <div className="text-primary-200 hover:text-primary-200/50 transition-all text-base cursor-pointer">
                                <span className="hidden md:flex">{t("navbar.references")}</span>
                            </div>
                        </a> */}
                        <a href="/#method">
                            <div className="text-primary-200 hover:text-primary-200/50 transition-all text-base cursor-pointer">
                                <span className="hidden md:flex">{t("navbar.methods")}</span>
                            </div>
                        </a>
                        <a href="/team">
                            <div className="text-primary-200 hover:text-primary-200/50 transition-all text-base cursor-pointer">
                                <span className="hidden md:flex">{t("navbar.team")}</span>
                            </div>
                        </a>
                        <a href="/etudes-de-cas">
                            <div className="text-primary-200 hover:text-primary-200/50 transition-all text-base cursor-pointer">
                                <span className="hidden md:flex">{t("navbar.studyCase")}</span>
                            </div>
                        </a>
                        {/* <Popover placement="bottom-start">
                            <PopoverHandler>
                                <button onClick={() => setIsPopoverOpen(!isPopoverOpen)} className={`${isPopoverOpen ? "text-primary-200/50" : "text-primary-200 hover:text-primary-200/50"} transition-all text-base cursor-pointer pr-2`}>
                                    <span className="hidden md:flex">{t("navbar.studyCase")}</span>
                                </button>
                            </PopoverHandler>
                            <PopoverContent placeholder="" className="z-40 font-serif flex flex-col min-w-[200px] mt-1 gap-3">
                                <a href="/etudes-de-cas/lio">
                                    <div className="text-primary-200 hover:opacity-80 transition-all text-sm cursor-pointer">
                                        <span className="flex">{t("navbar.studyCases.lio")}</span>
                                    </div>
                                </a>
                            </PopoverContent>
                        </Popover> */}
                        <a
                            href="/work-together"
                            className="text-white text-base bg-primary-200 hover:bg-primary-200/80 transition-all px-4 py-2 rounded-full cursor-pointer"
                        >
                            <span className="hidden md:flex">{t("navbar.estimateYourProject")}</span>
                            <FontAwesomeIcon icon="paper-plane" className="flex md:hidden" />
                        </a>
                    </div>
                </div>
            </div>

            <Drawer placeholder="" open={open} onClose={closeDrawer} className="p-4">
                <div className="mb-6 flex items-center justify-between">
                    <Typography placeholder="" variant="h5" className="text-primary-100 uppercase">
                        Capeima
                    </Typography>
                    <IconButton placeholder="" variant="text" className="text-primary-100" onClick={closeDrawer}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="h-5 w-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </IconButton>
                </div>
                <div className="flex flex-col gap-4">
                    {/* <a href="/#solution">
                        <div className="text-[#355379] hover:opacity-80 transition-all text-base cursor-pointer">
                            <span className="flex">{t("navbar.services")}</span>
                        </div>
                    </a> */}
                    {/* <a href="/#references">
                        <div className="text-[#355379] hover:opacity-80 transition-all text-base cursor-pointer">
                            <span className="flex">{t("navbar.references")}</span>
                        </div>
                    </a> */}
                    <a href="/#method">
                        <div className="text-[#355379] hover:opacity-80 transition-all text-base cursor-pointer">
                            <span className="flex">{t("navbar.methods")}</span>
                        </div>
                    </a>
                    <a href="/team">
                        <div className="text-[#355379] hover:opacity-80 transition-all text-base cursor-pointer">
                            <span className="flex">{t("navbar.team")}</span>
                        </div>
                    </a>
                    <a href="/etudes-de-cas">
                        <div className="text-[#355379] hover:opacity-80 transition-all text-base cursor-pointer">
                            <span className="flex">{t("navbar.studyCase")}</span>
                        </div>
                    </a>
                    <a href="/work-together">
                        <div className="text-white text-base bg-primary-200 hover:bg-primary-200/80 transition-all px-4 py-2 rounded-full cursor-pointer w-fit mx-auto">
                            <span className="flex">{t("navbar.estimateYourProject")}</span>
                        </div>
                    </a>
                </div>
            </Drawer>
        </>
    );
}
