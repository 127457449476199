import { useTranslation } from "react-i18next";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/NavbarWithoutAnimation";

export default function Page404() {
    const { t } = useTranslation();

    return (
        <div className="w-full bg-gradient-to-br from-primary-100 to-primary-200">
            <Navbar />
            <div className="w-full min-h-screen flex flex-col px-4 md:px-16 pt-24 mb-12 max-w-[1280px] mx-auto">
                <span className="uppercase tracking-[0.2rem] text-white text-xs md:text-sm text-center px-4">{t("error404.preTitle")}</span>
                <h1 className="uppercase text-white text-xl md:text-2xl text-center px-4">{t("error404.title")}</h1>
                <div className="mt-4 mb-mt-0 w-full flex flex-col items-center gap-4 bg-white rounded-lg lg:mt-4 pt-4 md:py-8 px-4 md:px-16 pb-4 font-serif text-justify text-sm text-black">
                    <div className="leading-6 text-xs md:text-[14px] 2xl:text-[16px] text-justify">
                        {t("error404.description")}
                    </div>
                    <a href="/">
                        <div className="text-white text-base bg-primary-200 hover:bg-primary-200/80 transition-all px-4 py-2 rounded-full cursor-pointer w-fit mx-auto">
                            <span className="flex">{t("error404.backToHome")}</span>
                        </div>
                    </a>
                </div>
            </div>
            <Footer />
        </div>
    );
}
