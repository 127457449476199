import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const iconsList = [
    {
        icon: "react",
        url: "https://static-00.iconduck.com/assets.00/react-icon-512x512-u6e60ayf.png",
    },
    {
        icon: "symfony",
        url: "https://cdn.iconscout.com/icon/free/png-256/free-symfony-3-1174988.png?f=webp",
    },
    {
        icon: "api-platform",
        url: "https://api-platform.com/images/logos/Logo_Circle%20webby%20text%20blue.png",
    },
    {
        icon: "node-js",
        url: "https://cdn-icons-png.flaticon.com/512/5968/5968322.png",
    },
    {
        icon: "express",
        url: "https://ajeetchaulagain.com/static/7cb4af597964b0911fe71cb2f8148d64/87351/express-js.png",
    },
    {
        icon: "mongodb",
        url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNyOe4xJhJFvDUbm1OSFnnEc4plFTvdYrBmOfNf-YUNA&s",
    },
    {
        icon: "mysql",
        url: "https://cdn2.boryl.fr/2020/12/fd8a25a0-mysql.svg",
    },
    {
        icon: "typescript",
        url: "https://humancoders-formations.s3.amazonaws.com/uploads/course/logo/230/thumb_bigger_formation-typescript.png",
    },
    {
        icon: "javascript",
        url: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Unofficial_JavaScript_logo_2.svg/1200px-Unofficial_JavaScript_logo_2.svg.png",
    },
    {
        icon: "html5",
        url: "https://cdn-icons-png.flaticon.com/256/174/174854.png",
    },
    {
        icon: "css3",
        url: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/62/CSS3_logo.svg/800px-CSS3_logo.svg.png",
    },
    {
        icon: "sass",
        url: "https://avatars.githubusercontent.com/u/317889?s=280&v=4",
    },
    {
        icon: "bootstrap",
        url: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Bootstrap_logo.svg/640px-Bootstrap_logo.svg.png",
    },
    {
        icon: "tailwindcss",
        url: "https://www.drupal.org/files/project-images/screenshot_361.png",
    },
    {
        icon: "figma",
        url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGS2AgRLgYbCiPFfpcI0QkIem5iCWSVYADnZ9mrgJOhg&s",
    },
    {
        icon: "git",
        url: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Git_icon.svg/2048px-Git_icon.svg.png",
    }
]

export default function PlanetMultiple() {
    const { t } = useTranslation();
    const [currentIcons, setCurrentIcons] = useState<{ icon: string, url: string }[]>([]);
    const [fade, setFade] = useState(false);

    useEffect(() => {
        const updateIcons = () => {
            const selectedIndices = new Set<number>();
            const randomIcons = [];

            while (randomIcons.length < 4) {
                const randomIndex = Math.floor(Math.random() * iconsList.length);
                if (!selectedIndices.has(randomIndex)) {
                    selectedIndices.add(randomIndex);
                    randomIcons.push(iconsList[randomIndex]);
                }
            }

            setCurrentIcons(randomIcons);
        };

        updateIcons();
        const interval = setInterval(() => {
            setFade(true);
            setTimeout(() => {
                updateIcons();
                setFade(false);
            }, 1000);
        }, 17500);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="max-w-[1280px] py-12 mb-12 flex flex-col md:flex-row justify-center items-center w-full mx-auto">
            <div className="w-full flex flex-col justify-center items-center">
                <div className="page">
                    <div className="container">
                        <div className="logo">
                        </div>
                        <div className="inDots">
                            <div className="dot icon-inner-2-1">
                                <img src={iconsList[0].url} alt={iconsList[0].icon} />
                            </div>
                        </div>
                        <div className="inDots-uncolore">
                            <div className="dot icon-inner-2-2">
                                <img src={iconsList[1].url} alt={iconsList[1].icon} />
                            </div>
                        </div>
                        <div className="inDots-uncolore">
                            <div className="dot icon-inner-2-3">
                                <img src={iconsList[2].url} alt={iconsList[2].icon} />
                            </div>
                        </div>
                        <div className="inDots-uncolore">
                            <div className="dot icon-inner-2-4">
                                <img src={iconsList[3].url} alt={iconsList[3].icon} />
                            </div>
                        </div>
                        
                        <div className="middle dots">
                            <div className="dot icon-inner-1-1">
                                <img src={iconsList[4].url} alt={iconsList[4].icon} />
                            </div>
                        </div>
                        <div className="middle-uncolore dots">
                            <div className="dot icon-inner-1-2">
                                <img src={iconsList[5].url} alt={iconsList[5].icon} />
                            </div>
                        </div>
                        <div className="middle-uncolore dots">
                            <div className="dot icon-inner-1-3">
                                <img src={iconsList[6].url} alt={iconsList[6].icon} />
                            </div>
                        </div>
                        <div className="middle-uncolore dots">
                            <div className="dot icon-inner-1-4">
                                <img src={iconsList[7].url} alt={iconsList[7].icon} />
                            </div>
                        </div>
                        
                        <div className="beforeOut">
                            <div className="dot icon-outer-2-1">
                                <img src={iconsList[8].url} alt={iconsList[8].icon} />
                            </div>
                        </div>
                        <div className="beforeOut-uncolore">
                            <div className="dot icon-outer-2-2">
                                <img src={iconsList[9].url} alt={iconsList[9].icon} />
                            </div>
                        </div>
                        <div className="beforeOut-uncolore">
                            <div className="dot icon-outer-2-3">
                                <img src={iconsList[10].url} alt={iconsList[10].icon} />
                            </div>
                        </div>
                        <div className="beforeOut-uncolore">
                            <div className="dot icon-outer-2-4">
                                <img src={iconsList[11].url} alt={iconsList[11].icon} />
                            </div>
                        </div>
                        
                        <div className="outer dots">
                            <div className="dot icon-outer-1-1">
                                <img src={iconsList[12].url} alt={iconsList[12].icon} />
                            </div>
                        </div>
                        <div className="outer-uncolore dots">
                            <div className="dot icon-outer-1-2">
                                <img src={iconsList[13].url} alt={iconsList[13].icon} />
                            </div>
                        </div>
                        <div className="outer-uncolore dots">
                            <div className="dot icon-outer-1-3">
                                <img src={iconsList[14].url} alt={iconsList[14].icon} />
                            </div>
                        </div>
                        <div className="outer-uncolore dots">
                            <div className="dot icon-outer-1-4">
                                <img src={iconsList[15].url} alt={iconsList[15].icon} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full flex flex-col justify-start items-center pl-4 pr-4 md:pr-12">
                <span className="uppercase tracking-[0.2rem] text-primary-100 text-xs md:text-sm w-full">{t("technologies.preTitle")}</span>
                <h1 className="uppercase text-xl md:text-2xl text-[#355379] font-medium w-full" dangerouslySetInnerHTML={{ __html: t("technologies.title") }}></h1>
                <div className="w-full flex flex-col gap-4">
                    <span
                        className="font-serif text-left font-normal text-base text-[#555A67] w-full"
                        dangerouslySetInnerHTML={{ __html: t("technologies.description") }}
                    ></span>
                </div>
            </div>
        </div>
    )
}