import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface BoxInvestProps {
    title: string;
    icon: string;
    selectedBoxes: string[];
    handleSelectBox: (title: string) => void;
}

export default function BoxInvest({ title, icon, selectedBoxes, handleSelectBox }: Readonly<BoxInvestProps>) {
    return (
        <button onClick={() => handleSelectBox(title)} className={`h-[120px] w-[120px] ${ selectedBoxes.includes(title) ? "bg-white text-primary-100" : "bg-white/20 text-white"} transition-all rounded-2xl p-0.5`}>
            <div className={`px-1 py-4 h-[116px] w-[116px] flex flex-col gap-2 justify-between items-center rounded-2xl border-2 mx-auto ${ selectedBoxes.includes(title) ? "border-primary-100" : "border-transparent"}`}>
                <FontAwesomeIcon icon={icon as IconProp} className="w-8 h-8" />
                <span className="font-medium text-sm text-center">{title}</span>
            </div>
        </button>
    );
}
