import { useTranslation } from "react-i18next";
import IAPicture from "../../../assets/img/Bento/ia_flashie.png";
import CustomPicture from "../../../assets/img/Bento/surmesure_flashie.png";
import AccessPicture from "../../../assets/img/Bento/integration_white_transparent.png";
import SecurityPicture from "../../../assets/img/Bento/securite_transparent.png";
import AdvicePicture from "../../../assets/img/Bento/conseil_sans_bordure.png";
import MadeInFrancePicture from "../../../assets/img/Bento/made-in-france_darker.png";
import { useAnimation } from "framer-motion";
import React, { useEffect } from "react";

export default function Us() {
    const { t } = useTranslation();
    const controls = useAnimation();
    const accessRef = React.useRef<HTMLDivElement>(null);
    const customRef = React.useRef<HTMLDivElement>(null);
    const iaRef = React.useRef<HTMLDivElement>(null);
    const securityRef = React.useRef<HTMLDivElement>(null);
    const adviceRef = React.useRef<HTMLDivElement>(null);
    const madeInFranceRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    if (adviceRef.current) adviceRef.current.classList.add("reveal-left");
                    if (iaRef.current) iaRef.current.classList.add("reveal-middle");
                    if (accessRef.current) accessRef.current.classList.add("reveal-right");
                }
            });
        });

        if (accessRef.current) observer.observe(accessRef.current);
        return () => {
            if (accessRef.current) observer.unobserve(accessRef.current);
        };
    }, [controls]);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    if (customRef.current) customRef.current.classList.add("reveal-left2");
                    if (securityRef.current) securityRef.current.classList.add("reveal-middle2");
                    if (madeInFranceRef.current) madeInFranceRef.current.classList.add("reveal-right2");
                }
            });
        });

        if (customRef.current) observer.observe(customRef.current);

        return () => {
            if (customRef.current) observer.unobserve(customRef.current);
        };
    }, [controls]);

    return (
        <div className="min-h-screen flex flex-col py-12 items-center bg-white mt-12">
            <span className="uppercase tracking-[0.2rem] text-primary-100 text-xs md:text-sm px-4">{t("us.preTitle")}</span>
            <h1
                className="mb-8 uppercase text-center text-xl md:text-2xl text-[#355379] font-medium px-4"
                dangerouslySetInnerHTML={{ __html: t("us.title") }}
            ></h1>
            <div className="h-full w-full px-8">
                <section className="bg-white">
                    <div className="py-4 px-2 mx-auto max-w-[1280px] sm:py-4 lg:px-6">
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            <div className="grid col-span-2 sm:col-span-1 bg-stone-50">
                                <div
                                    ref={adviceRef}
                                    style={{ height: window.innerWidth < 768 ? 350 : 450 }}
                                    className="md:opacity-0 relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 mb-4"
                                >
                                    <div className="absolute top-5 right-5 z-10">
                                        <img alt="advice bento" src={AdvicePicture} width={150} />
                                    </div>
                                    <div className="absolute inset-0 bg-gradient-to-r from-primary-200 to-primary-100"></div>
                                    <div className="z-10 font-medium text-white absolute bottom-0 left-0 p-4 flex flex-col">
                                        <span className="text-sm xs:text-xs md:text-sm">{t("us.boxes.advice.description")}</span>
                                        <span className="text-2xl xs:text-xl md:text-3xl">{t("us.boxes.advice.title")}</span>
                                    </div>
                                </div>
                                <div
                                    ref={customRef}
                                    style={{ height: window.innerWidth < 768 ? 350 : 325 }}
                                    className="md:opacity-0 relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 bg-white border-2 border-primary-100"
                                >
                                    <div className="absolute top-5 right-5">
                                        <img alt="custom bento" src={CustomPicture} width={150} />
                                    </div>
                                    <div className="z-10 font-medium text-primary-100 absolute bottom-0 left-0 p-4 flex flex-col">
                                        <span className="text-sm xs:text-xs md:text-sm">{t("us.boxes.custom.description")}</span>
                                        <span className="text-2xl xs:text-xl md:text-3xl font-semibold">{t("us.boxes.custom.title")}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="grid col-span-2 sm:col-span-1 bg-stone-50">
                                <div
                                    ref={iaRef}
                                    style={{ height: window.innerWidth < 768 ? 350 : 325 }}
                                    className="md:opacity-0 relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 mb-4 bg-white border-2 border-primary-100"
                                >
                                    <div className="absolute top-5 right-5">
                                        <img alt="ia bento" src={IAPicture} width={150} />
                                    </div>
                                    <div className="z-10 font-medium text-primary-100 absolute bottom-0 left-0 p-4 flex flex-col">
                                        <span className="text-sm xs:text-xs md:text-sm">{t("us.boxes.ia.description")}</span>
                                        <span className="text-2xl xs:text-xl md:text-3xl">{t("us.boxes.ia.title")}</span>
                                    </div>
                                </div>
                                <div
                                    ref={securityRef}
                                    style={{ height: window.innerWidth < 768 ? 350 : 450 }}
                                    className="md:opacity-0 relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40"
                                >
                                    <div className="absolute top-5 right-5 z-10">
                                        <img alt="security bento" src={SecurityPicture} width={150} />
                                    </div>
                                    <div className="absolute inset-0 bg-gradient-to-r from-primary-200 to-primary-100"></div>
                                    <div className="z-10 font-medium text-white absolute bottom-0 left-0 p-4 flex flex-col">
                                        <span className="text-sm xs:text-xs md:text-sm">{t("us.boxes.security.description")}</span>
                                        <span className="text-2xl xs:text-xl md:text-3xl">{t("us.boxes.security.title")}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="grid col-span-2 sm:col-span-1 bg-stone-50">
                                <div
                                    ref={accessRef}
                                    style={{ height: window.innerWidth < 768 ? 350 : 450 }}
                                    className="md:opacity-0 relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 mb-4"
                                >
                                    <div className="absolute top-5 right-5 z-10">
                                        <img alt="access bento" src={AccessPicture} width={150} />
                                    </div>
                                    <div className="absolute inset-0 bg-gradient-to-r from-primary-200 to-primary-100"></div>
                                    <div className="z-10 font-medium text-white absolute bottom-0 left-0 p-4 flex flex-col">
                                        <span className="text-sm xs:text-xs md:text-sm">{t("us.boxes.access.description")}</span>
                                        <span className="text-2xl xs:text-xl md:text-3xl">{t("us.boxes.access.title")}</span>
                                    </div>
                                </div>
                                <div
                                    ref={madeInFranceRef}
                                    style={{ height: window.innerWidth < 768 ? 350 : 325 }}
                                    className="md:opacity-0 relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 bg-white border-2 border-primary-100"
                                >
                                    <div className="absolute top-5 right-5 z-10">
                                        <img alt="made in france bento" src={MadeInFrancePicture} width={150} />
                                    </div>
                                    <div className="z-10 font-medium text-primary-100 absolute bottom-0 left-0 p-4 flex flex-col">
                                        <span className="text-sm xs:text-xs md:text-sm">{t("us.boxes.madeInFrance.description")}</span>
                                        <span className="text-2xl xs:text-xl md:text-3xl">{t("us.boxes.madeInFrance.title")}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}
