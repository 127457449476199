import { useTranslation } from "react-i18next";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/NavbarWithoutAnimation";

import QuentinCapelle from "../../assets/img/Team/Quentin.png"
import MathiasAndre from "../../assets/img/Team/Mathias.png"
import MaximePayant from "../../assets/img/Team/Maxime.png"
import AmozPay from "../../assets/img/Team/Amoz.png"
import ChloeDole from "../../assets/img/Team/Chloe.png"
import BaptisteBarbotin from "../../assets/img/Team/Baptiste.png"
import AmbitiousProject from "../../components/StudyCase/AmbitiousProject";

const dirigeants = [
    {
        name: "Quentin Capelle",
        job: "cofounder",
        picture: QuentinCapelle
    },
    {
        name: "Mathias Andre",
        job: "cofounder",
        picture: MathiasAndre
    },
]

const developpeurs = [
    {
        name: "Maxime Payant",
        job: "developer",
        picture: MaximePayant
    },
    {
        name: "Amoz Pay",
        job: "devOps",
        picture: AmozPay
    },
    {
        name: "Chloé Dole",
        job: "marketing",
        picture: ChloeDole
    },
    {
        name: "Baptiste Barbotin",
        job: "developer",
        picture: BaptisteBarbotin
    }
]

export default function Team() {
    const { t } = useTranslation();

    return (
        <div className="w-full bg-white relative">
            <Navbar />
            <div className="absolute z-0 w-full h-64 -mt-12 top-0 left-0 bg-gradient-to-r from-primary-100 to-primary-200 rounded-b-[50px]"></div>
            <div className="relative z-10 w-full flex flex-col px-4 md:px-16 pt-24 mt-12 mb-16 max-w-[1408px] mx-auto">
                <div className="bg-white max-w-[1280px] w-full mx-auto py-8 md:py-8 px-4 md:px-12 rounded-[50px] flex flex-col md:flex-row flex-wrap">
                    <div className="w-full md:w-1/2 md:pr-4 flex flex-col pt-6">
                        <span className="uppercase tracking-[0.2rem] text-primary-100 text-xs md:text-sm text-left px-4">{t("team.preTitle")}</span>
                        <h1 className="mb-1 uppercase font-medium text-[#355379] text-xl md:text-2xl text-left px-4" dangerouslySetInnerHTML={{ __html: t("team.title") }}></h1>
                        <span className="text-[#355379] text-sm md:text-base text-left px-4">{t("team.description")}</span>
                    </div>
                    <div className="w-full md:w-1/2 md:pl-4 grid grid-cols-1 lg:grid-cols-2 mt-4 md:mt-0">
                        {dirigeants.map((dirigeant) => (
                            <div key={dirigeant.name} className="flex flex-col items-center justify-center px-10 py-4 md:py-6 max-w-[275px] mx-auto">
                                <img src={dirigeant.picture} alt={dirigeant.name} className="rounded-full" />
                                <span className="font-medium uppercase text-[#355379] text-sm md:text-base text-center mt-2">
                                    {dirigeant.name}
                                </span>
                                <span className="text-primary-100 text-xs text-center uppercase tracking-[0.2rem]">
                                    {t(`team.job.${dirigeant.job}`)}
                                </span>
                            </div>
                        ))}
                    </div>
                    <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                        {developpeurs.map((dev) => (
                            <div key={dev.name} className="flex flex-col items-center justify-center px-10 py-4 md:py-6 max-w-[275px] mx-auto">
                                <img src={dev.picture} alt={dev.name} className="rounded-full aspect-square" />
                                <span className="font-medium uppercase text-[#355379] text-sm md:text-base text-center mt-2">
                                    {dev.name}
                                </span>
                                <span className="text-primary-100 text-xs text-center uppercase tracking-[0.2rem]">
                                    {t(`team.job.${dev.job}`)}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <AmbitiousProject />
            <Footer />
        </div>
    )
}