import { Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

export default function Footer() {
    const { t } = useTranslation();

    return (
        <div className="bg-[#2D3341] text-white w-full -mb-12">
            <div className="w-full md:w-2/3 mx-auto flex flex-wrap md:flex-nowrap px-4 md:px-0 pt-16 pb-4">
                <div className="w-full md:w-1/4">
                    <div className="text-white text-base md:text-2xl">
                        <span className="uppercase font-sans">{t("brand.name")}</span>
                    </div>
                </div>
                <div className="w-1/2 md:w-1/4 flex flex-col gap-2 pt-2">
                    <Typography placeholder="" color="white" variant="paragraph" className="text-white font-serif font-semibold">
                        <span className="flex">{t("footer.links.title")}</span>
                    </Typography>
                    <a href="/">
                        <Typography placeholder="" color="white" variant="small" className="text-white font-serif hover:underline">
                            <span className="flex">{t("footer.links.home")}</span>
                        </Typography>
                    </a>
                    {/* <a href="/#solution">
                        <Typography placeholder="" color="white" variant="small" className="text-white font-serif hover:underline">
                            <span className="flex">{t("footer.links.services")}</span>
                        </Typography>
                    </a> */}
                    <a href="/#method">
                        <Typography placeholder="" color="white" variant="small" className="text-white font-serif hover:underline">
                            <span className="flex">{t("footer.links.methods")}</span>
                        </Typography>
                    </a>
                    {/* <a href="/#references">
                        <Typography placeholder="" color="white" variant="small" className="text-white font-serif hover:underline">
                            <span className="flex">{t("footer.links.references")}</span>
                        </Typography>
                    </a> */}
                    <a href="/team">
                        <Typography placeholder="" color="white" variant="small" className="text-white font-serif hover:underline">
                            <span className="flex">{t("footer.links.team")}</span>
                        </Typography>
                    </a>
                </div>
                <div className="w-1/2 md:w-1/4 flex flex-col gap-2 pt-2">
                    <Typography placeholder="" color="white" variant="paragraph" className="text-white font-serif font-semibold">
                        <span className="flex">{t("footer.socials.title")}</span>
                    </Typography>
                    <a href="https://www.linkedin.com/company/capeima/" target="_blank">
                        <Typography placeholder="" color="white" variant="small" className="text-white font-serif hover:underline">
                            <span className="flex">{t("footer.socials.linkedin")}</span>
                        </Typography>
                    </a>
                    <div className="flex md:hidden w-full flex-col gap-2 pt-4">
                        <Typography placeholder="" color="white" variant="paragraph" className="text-white font-serif font-semibold">
                            <span className="flex">{t("footer.office.title")}</span>
                        </Typography>
                        <a href="/#" target="_blank">
                            <Typography placeholder="" color="white" variant="small" className="text-white font-serif hover:underline">
                                <span className="flex">{t("footer.office.address")}</span>
                            </Typography>
                        </a>
                    </div>
                </div>
                <div className="hidden md:flex md:w-1/4 flex-col gap-2 pt-2">
                    <Typography placeholder="" color="white" variant="paragraph" className="text-white font-serif font-semibold">
                        <span className="flex">{t("footer.office.title")}</span>
                    </Typography>
                    <a href="https://www.google.com/maps/place/160+Av.+Jean+Jaur%C3%A8s,+69007+Lyon/@45.7399984,4.8358943,16z/data=!3m1!4b1!4m6!3m5!1s0x47f4ea398762292f:0x640761c4165d4afa!8m2!3d45.7399947!4d4.8384692!16s%2Fg%2F11c1hcwl9r?entry=ttu" target="_blank">
                        <Typography placeholder="" color="white" variant="small" className="text-white font-serif hover:underline">
                            <span className="flex">{t("footer.office.address")}</span>
                        </Typography>
                    </a>
                    <Typography placeholder="" color="white" variant="small" className="text-white font-serif text-xs w-full pt-8">
                        <span className="flex w-full">{t("footer.copyrights.part1")}</span>
                        <span className="flex w-full">{t("footer.copyrights.part2")}</span>
                    </Typography>
                </div>
            </div>
            <div className="md:hidden w-full px-4 text-center">
                <Typography placeholder="" color="white" variant="small" className="text-white font-serif text-xs w-full pt-8 text-center">
                    <span className="flex w-full justify-center">{t("footer.copyrights.part1")}</span>
                    <span className="flex w-full justify-center">{t("footer.copyrights.part2")}</span>
                </Typography>
            </div>
            <div className="w-full md:w-2/3 mx-auto flex flex-col md:flex-row pt-4 pb-16 items-center justify-center">
            <div className="w-full md:w-1/2 flex justify-center items-center">
                    <a href="mailto:contact@capeima.fr">
                        <Typography placeholder="" color="white" variant="small" className="text-white font-serif hover:underline">
                            <span className="flex">{t("footer.contact.email")}</span>
                        </Typography>
                    </a>
                </div>
                <div className="w-full md:w-1/2 flex justify-center items-center">
                    <a href="/mentions-legales">
                        <Typography placeholder="" color="white" variant="small" className="text-white font-serif hover:underline">
                            <span className="flex">{t("footer.mentionslegales")}</span>
                        </Typography>
                    </a>
                </div>
                <div className="w-full md:w-1/2 flex justify-center items-center">
                    <a href="tel:+33784067828">
                        <Typography placeholder="" color="white" variant="small" className="text-white font-serif hover:underline">
                            <span className="flex">{t("footer.contact.phone")}</span>
                        </Typography>
                    </a>
                </div>
            </div>
        </div>
    )
}