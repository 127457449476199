import { useTranslation } from "react-i18next";

interface ChallengeProps {
    nameForTranslation: string;
}

export default function Challenge({ nameForTranslation }: ChallengeProps) {
    const { t } = useTranslation();

    return (
        <div className="w-full flex items-center justify-center py-12 bg-white h-full px-4 md:px-12">
            <div className="flex flex-col justify-center items-center gap-1 max-w-[1280px] w-full h-full relative px-4 md:px-12 bg-[#f7f7fa] rounded-2xl py-8">
                <div className="relative z-10 w-full md:text-center uppercase tracking-[0.2rem] text-primary-100 text-xs md:text-sm">
                    {t("studyCase.commun.challenge")}
                </div>
                <h1 className="w-fit uppercase text-xl md:text-2xl text-[#355379] font-medium md:text-center">
                    {t(`studyCase.${nameForTranslation}.challenge_t`)}
                </h1>
                <div className="leading-7 text-xs md:text-[14px] text-justify">
                    {t(`studyCase.${nameForTranslation}.challenge_d`)}
                </div>
            </div>
        </div>
    )
}