import { useTranslation } from "react-i18next";

import CornerLeft from "../../../assets/img/decorations/topLeftCornerTransparent.png"
import CornerRight from "../../../assets/img/decorations/topRightCorner.png"

export default function CardsServices() {
    const { t } = useTranslation();

    return (
        <div className="flex items-center justify-center my-8 2xl:my-12 mt-16">
            <div className="flex flex-col py-12 max-w-[1280px] w-full relative">
                <div className="hidden md:flex absolute left-4 md:left-12 top-0">
                    <img src={CornerLeft} alt="top corner" className="w-12 lg:w-20 opacity-50" />
                </div>
                <div className="absolute right-4 md:right-12 top-0">
                    <img src={CornerRight} alt="top corner" className="w-16 lg:w-20 opacity-50" />
                </div>
                <span className="uppercase tracking-[0.2rem] text-primary-100 text-xs md:text-sm px-6 md:px-24 text-left">{t("cardsService.preTitle")}</span>
                <h1 className="uppercase text-xl md:text-2xl text-[#355379] font-medium px-6 md:px-24 text-left" dangerouslySetInnerHTML={{ __html: t("cardsService.title") }}></h1>
                <h1 className="mb-8 uppercase text-base text-[#355379] px-6 md:px-24 text-left" dangerouslySetInnerHTML={{ __html: t("cardsService.title_d") }}></h1>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-8 mt-8 px-4 md:px-12">
                    <div className="bg-gradient-to-r from-primary-100 to-primary-200 relative rounded-3xl">
                        <div className="absolute h-12 flex rounded-full items-center justify-center bg-white -top-6 left-1/2 -translate-x-1/2 px-12 py-2 z-10">
                            <span className="text-base md:text-xl font-bold text-[#355379] whitespace-nowrap uppercase">{t("cardsService.boxes.box1.title")}</span>
                        </div>
                        <div className="relative top-1.5 left-1.5 w-[calc(100%-12px)] h-[calc(100%-12px)] bg-white rounded-[18px] px-5 pt-5 pb-8 text-[#555A67]">
                            {t("cardsService.boxes.box1.description")}
                        </div>
                    </div>
                    <div className="bg-gradient-to-r from-primary-100 to-primary-200 relative rounded-3xl">
                        <div className="absolute h-12 flex rounded-full items-center justify-center bg-white -top-6 left-1/2 -translate-x-1/2 px-12 py-2 z-10">
                            <span className="text-base md:text-xl font-bold text-[#355379] whitespace-nowrap uppercase">{t("cardsService.boxes.box2.title")}</span>
                        </div>
                        <div className="relative top-1.5 left-1.5 w-[calc(100%-12px)] h-[calc(100%-12px)] bg-white rounded-[18px] px-5 pt-5 pb-8 text-[#555A67]">
                            {t("cardsService.boxes.box2.description")}
                        </div>
                    </div>
                    <div className="bg-gradient-to-r from-primary-100 to-primary-200 relative rounded-3xl">
                        <div className="absolute h-12 flex rounded-full items-center justify-center bg-white -top-6 left-1/2 -translate-x-1/2 px-12 py-2 z-10">
                            <span className="text-base md:text-xl font-bold text-[#355379] whitespace-nowrap uppercase">{t("cardsService.boxes.box3.title")}</span>
                        </div>
                        <div className="relative top-1.5 left-1.5 w-[calc(100%-12px)] h-[calc(100%-12px)] bg-white rounded-[18px] px-5 pt-5 pb-8 text-[#555A67]">
                            {t("cardsService.boxes.box3.description")}
                        </div>
                    </div>
                    <div className="bg-gradient-to-r from-primary-100 to-primary-200 relative rounded-3xl">
                        <div className="absolute h-12 flex rounded-full items-center justify-center bg-white -top-6 left-1/2 -translate-x-1/2 px-12 py-2 z-10">
                            <span className="text-base md:text-xl font-bold text-[#355379] whitespace-nowrap uppercase">{t("cardsService.boxes.box4.title")}</span>
                        </div>
                        <div className="relative top-1.5 left-1.5 w-[calc(100%-12px)] h-[calc(100%-12px)] bg-white rounded-[18px] px-5 pt-5 pb-8 text-[#555A67]">
                            {t("cardsService.boxes.box4.description")}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}