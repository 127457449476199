import { useTranslation } from "react-i18next";

export default function AmbitiousProject() {
    const { t } = useTranslation();

    return (
        <div className="w-full bg-gradient-to-r from-primary-100 to-primary-200 p-6 flex flex-col justify-center items-center gap-1">
            <div className="max-w-[1280px] uppercase text-white text-xl tracking-widest text-center">
                {t("studyCase.commun.ambitious")}
            </div>
            <div className="max-w-[1280px] text-white text-base text-center">
                {t("studyCase.commun.ambitious_d")}
            </div>
            <a href="/work-together" className="mt-2">
                <div className="text-white text-base bg-white/40 hover:bg-white/20 transition-all px-4 py-2 rounded-full cursor-pointer w-fit mx-auto">
                    <span className="flex">{t("navbar.estimateYourProject")}</span>
                </div>
            </a>
        </div>
    )
}