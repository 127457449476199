import { useTranslation } from "react-i18next";
import BottomCorner from "../../assets/img/decorations/bottomRightCorner.png";
import BorderBottom from "../../assets/img/decorations/borderBottomSmaller.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface DevelopmentCardsProps {
    iteration: number;
    picture: string;
    nameForTranslation: string;
    contentSide?: "left" | "right";
    nbrPoints?: number;
}

function PictureSide({ picture, iteration, nameForTranslation }: { picture: string; iteration: number; nameForTranslation: string }) {
    return (
        <div className="w-full">
            <img src={picture} alt={`technique-${iteration} - ${nameForTranslation}`} className="w-full rounded-2xl" />
        </div>
    );
}

function ContentSide({ iteration, nameForTranslation, nbrPoints }: { iteration: number; nameForTranslation: string; nbrPoints: number }) {
    const { t } = useTranslation();

    return (
        <div className="w-full flex flex-col gap-1 relative h-full">
            <div className="relative z-10 w-fit text-left uppercase tracking-[0.2rem] text-primary-100 text-xs md:text-sm bg-white">
                {iteration === -1
                    ? t("studyCase.commun.company")
                    : t("studyCase.commun.technicalSolution")
                }
            </div>
            <h1 className="w-fit uppercase text-xl md:text-2xl text-[#355379] font-medium bg-white">
                {iteration === -1 
                    ? t(`studyCase.${nameForTranslation}.company_t`)
                    : t(`studyCase.${nameForTranslation}.technicalSolution_t_${iteration}`)
                }
            </h1>
            <div className="leading-6 text-xs md:text-[14px] 2xl:text-[16px] max-w-[450px] text-justify relative">
                {iteration === -1 
                    ? t(`studyCase.${nameForTranslation}.company_d`)
                    : t(`studyCase.${nameForTranslation}.technicalSolution_d_${iteration}`)
                }
                {iteration === -1 && (
                    <img src={BorderBottom} alt="border bottom" className="w-full absolute -bottom-4" />
                )}
            </div>
            {nbrPoints > 0 && (
                <div className="flex flex-col gap-1 mt-2">
                    {[...Array(nbrPoints)].map((_, index) => (
                        <div className="flex gap-2 items-center">
                            <FontAwesomeIcon icon="chevron-right" className="text-primary-100" />
                            <div className="leading-5 text-xs md:text-[12px] 2xl:text-[14px]">
                                {t(`studyCase.${nameForTranslation}.technicalSolution_bp_${iteration}.bp${index + 1}`)}
                            </div>
                        </div>
                    ))}       
                </div>
            )}
        </div>
    );
}

export default function DevelopmentCards({ iteration, picture, nameForTranslation, contentSide = "right", nbrPoints = 0 }: DevelopmentCardsProps) {
    return (
        <div className="w-full flex items-center justify-center py-12 bg-white h-full">
            <div className={`flex ${contentSide === "right" ? "flex-col-reverse" : "flex-col"} md:flex-row justify-center items-center gap-12 max-w-[1280px] w-full h-full relative px-4 md:px-12`}>
                <div className="w-full h-full flex items-stretch">
                    {contentSide === "left" ? (
                        <PictureSide picture={picture} iteration={iteration} nameForTranslation={nameForTranslation} />
                    ) : (
                        <ContentSide iteration={iteration} nameForTranslation={nameForTranslation} nbrPoints={nbrPoints} />
                    )}
                </div>
                <div className="w-full h-full flex items-stretch">
                    {contentSide === "left" ? (
                        <ContentSide iteration={iteration} nameForTranslation={nameForTranslation} nbrPoints={nbrPoints} />
                    ) : (
                        <PictureSide picture={picture} iteration={iteration} nameForTranslation={nameForTranslation} />
                    )}
                </div>
                {contentSide === "left" && <img src={BottomCorner} alt="bottom corner" className="w-12 sm:w-16 md:w-12 lg:w-24 absolute right-4 md:right-12 bottom-0" />}
            </div>
        </div>
    );
}
