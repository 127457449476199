import { useState } from "react";
import BoxInvest from "./BoxInvest";
import { useTranslation } from "react-i18next";

interface BoxResponsiveProps {
    listOfBoxes: string[];
    handleSelect: (title: string) => void;
}

export default function BoxResponsive({ listOfBoxes, handleSelect } : Readonly<BoxResponsiveProps>) {
    const { t } = useTranslation();
    const [showMore, setShowMore] = useState(false);

    return (
        <div className="flex flex-wrap gap-4 justify-center pt-8 pb-2 md:py-4">
            <BoxInvest handleSelectBox={handleSelect} selectedBoxes={listOfBoxes} title={t("invest.keywords.customerReliationship")} icon="handshake" />
            <BoxInvest handleSelectBox={handleSelect} selectedBoxes={listOfBoxes} title={t("invest.keywords.dashboard")} icon="table-columns" />
            <BoxInvest handleSelectBox={handleSelect} selectedBoxes={listOfBoxes} title={t("invest.keywords.workflow")} icon="code-pull-request" />
            <BoxInvest handleSelectBox={handleSelect} selectedBoxes={listOfBoxes} title={t("invest.keywords.visio")} icon="headset" />
            <button onClick={() => setShowMore(!showMore)} className="text-white text-xs mx-auto md:mb-0 bg-white/40 hover:bg-white/30 transition-all rounded-full px-4 py-2">{t("invest.showmore")}</button>
            <div className={`flex flex-wrap gap-4 justify-center transition-all duration-700 overflow-hidden ${showMore ? "h-400 opacity-100" : "h-0 opacity-0"}`}>
                <BoxInvest handleSelectBox={handleSelect} selectedBoxes={listOfBoxes} title={t("invest.keywords.delivery")} icon="truck" />
                <BoxInvest handleSelectBox={handleSelect} selectedBoxes={listOfBoxes} title={t("invest.keywords.documents")} icon="file" />
                <BoxInvest handleSelectBox={handleSelect} selectedBoxes={listOfBoxes} title={t("invest.keywords.payment")} icon="credit-card" />
                <BoxInvest handleSelectBox={handleSelect} selectedBoxes={listOfBoxes} title={t("invest.keywords.stocks")} icon="cubes" />
                <BoxInvest handleSelectBox={handleSelect} selectedBoxes={listOfBoxes} title={t("invest.keywords.ia")} icon="brain" />
                <BoxInvest handleSelectBox={handleSelect} selectedBoxes={listOfBoxes} title={t("invest.keywords.shop")} icon="cart-shopping" />
                <BoxInvest handleSelectBox={handleSelect} selectedBoxes={listOfBoxes} title={t("invest.keywords.meeting")} icon="calendar-day" />
                <BoxInvest handleSelectBox={handleSelect} selectedBoxes={listOfBoxes} title={t("invest.keywords.identification")} icon="fingerprint" />
                <BoxInvest handleSelectBox={handleSelect} selectedBoxes={listOfBoxes} title={t("invest.keywords.invoices")} icon="file-invoice-dollar" />
                <BoxInvest handleSelectBox={handleSelect} selectedBoxes={listOfBoxes} title={t("invest.keywords.conferences")} icon="microphone" />
                <BoxInvest handleSelectBox={handleSelect} selectedBoxes={listOfBoxes} title={t("invest.keywords.sells")} icon="barcode" />
                <BoxInvest handleSelectBox={handleSelect} selectedBoxes={listOfBoxes} title={t("invest.keywords.recruitment")} icon="user-plus" />
            </div>
        </div>
    )
}