import { Typography } from "@material-tailwind/react";
import { motion, useScroll, useTransform } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import WelcomePicture from "../../../assets/img/SecondSection.png";
import DecorationsWelcome from "../../../assets/img/decorations/bubblesWelcome.png";

export default function Welcome() {
    const { t } = useTranslation();
    const ref1 = useRef<HTMLDivElement>(null);
    const ref2 = useRef<HTMLDivElement>(null);
    const ref3 = useRef<HTMLDivElement>(null);
    const [distances, setDistances] = useState<{ start: number; end: number }[]>([
        {
            start: 0,
            end: 0,
        },
        {
            start: 0,
            end: 0,
        },
        {
            start: 0,
            end: 0,
        },
        {
            start: 0,
            end: 0,
        },
    ]);

    const { scrollY } = useScroll();

    useEffect(() => {
        const handleResize = () => {
            const halfHeightPage = window.innerHeight / 3;

            if (!ref1.current || !ref2.current || !ref3.current) return;

            const beginRef1 = ref1.current.getBoundingClientRect().top + window.scrollY - halfHeightPage * 2;
            const endRef1 = ref1.current.getBoundingClientRect().bottom + window.scrollY - halfHeightPage * 2;
            const beginRef2 = ref2.current.getBoundingClientRect().top + window.scrollY - halfHeightPage * 2;
            const endRef2 = ref2.current.getBoundingClientRect().bottom + window.scrollY - halfHeightPage * 2;
            const beginRef3 = ref3.current.getBoundingClientRect().top + window.scrollY - halfHeightPage * 2;
            const endRef3 = ref3.current.getBoundingClientRect().bottom + window.scrollY - halfHeightPage * 2;

            setDistances([
                { start: beginRef1 - 20, end: endRef1 + 20 },
                { start: beginRef2 - 20, end: endRef2 + 20 },
                { start: beginRef3 - 20, end: endRef3 + 20 },
            ]);
        };
        window.addEventListener("scroll", handleResize);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("scroll", handleResize);
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const scale1 = useTransform(scrollY, [distances[0].start, distances[0].end], ["1", "1.1"]);
    const scale2 = useTransform(scrollY, [distances[1].start, distances[1].end], ["1", "1.1"]);
    const scale3 = useTransform(scrollY, [distances[2].start, distances[2].end], ["1", "1.1"]);

    const weight1 = useTransform(scrollY, [distances[0].start, distances[0].end], [300, 600]);
    const weight2 = useTransform(scrollY, [distances[1].start, distances[1].end], [300, 600]);
    const weight3 = useTransform(scrollY, [distances[2].start, distances[2].end], [300, 600]);

    const color1 = useTransform(scrollY, [distances[0].start, distances[0].end], ["#ddd", "#029BDD"]);
    const color2 = useTransform(scrollY, [distances[1].start, distances[1].end], ["#ddd", "#029BDD"]);
    const color3 = useTransform(scrollY, [distances[2].start, distances[2].end], ["#ddd", "#029BDD"]);

    return (
        // <div className="min-h-screen bg-white w-full flex flex-col justify-center items-center pt-2 md:pt-2 pb-12 md:pb-2">
        //     <div className="flex flex-col justify-center gap-8 md:gap-16 items-center w-full">
        //         <div className="w-full flex flex-col md:flex-row items-center justify-center">
        <div className="max-w-[1280px] min-h-screen bg-white flex flex-col md:flex-row justify-center items-center w-full py-24 mx-auto px-4 md:px-8">
            <div className="w-full md:w-2/3 flex flex-col justify-center items-center">
                <div className="flex flex-col justify-center gap-8 md:gap-16 items-center w-fit">
                    {/* <div className="w-full flex flex-col md:flex-row items-center justify-end"> */}
                    <div className="w-full flex flex-col md:flex-row items-center justify-center">
                        <motion.div
                            style={{ color: color1, fontWeight: weight1, scale: scale1, transformOrigin: "right" }}
                            ref={ref1}
                            className="hidden md:block w-full md:w-1/3 text-right px-4 font-serif text-[30px] md:text-[24px] lg:text-[2rem]"
                        >
                            {t("welcome.keywords.part1")}
                        </motion.div>
                        <div className="w-full md:w-2/3 flex flex-col gap-4 px-6 md:px-4">
                            <div className="flex flex-col gap-2">
                                <span className="font-serif font-bold text-2xl text-[#355379]" dangerouslySetInnerHTML={{ __html: t("welcome.title") }}></span>
                                <span
                                    className="font-serif text-left font-normal text-base text-[#555A67] w-full"
                                    dangerouslySetInnerHTML={{ __html: t("welcome.descriptions.part1") }}
                                ></span>
                            </div>
                        </div>
                    </div>
                    {/* <div className="w-full flex flex-col md:flex-row items-center justify-center"> */}
                    {/* <div className="w-full flex flex-col md:flex-row items-center justify-end"> */}
                    <div className="w-full flex flex-col md:flex-row items-center justify-center">
                        <motion.div
                            style={{ color: color2, fontWeight: weight2, scale: scale2, transformOrigin: "right" }}
                            ref={ref2}
                            className="hidden md:block w-full md:w-1/3 text-left md:text-right px-4 opacity-100 font-serif text-[30px] md:text-[24px] lg:text-[2rem]"
                        >
                            {t("welcome.keywords.part2")}
                        </motion.div>
                        <div className="w-full md:w-2/3 flex flex-col gap-4 px-6 md:px-4">
                            <span
                                className="font-serif text-left font-normal text-base text-[#555A67] w-full"
                                dangerouslySetInnerHTML={{ __html: t("welcome.descriptions.part2") }}
                            ></span>
                        </div>
                    </div>
                    {/* <div className="w-full flex flex-col md:flex-row items-center justify-center"> */}
                    {/* <div className="w-full flex flex-col md:flex-row items-center justify-end"> */}
                    <div className="w-full flex flex-col md:flex-row items-center justify-center">
                        <motion.div
                            style={{ color: color3, fontWeight: weight3, scale: scale3, transformOrigin: "right" }}
                            ref={ref3}
                            className="hidden md:block w-full md:w-1/3 text-right px-4 opacity-100 font-serif text-[30px] md:text-[24px] lg:text-[2rem]"
                        >
                            {t("welcome.keywords.part3")}
                        </motion.div>
                        <div className="w-full md:w-2/3 flex flex-col gap-4 px-6 md:px-4">
                            <span
                                className="font-serif text-left font-normal text-base text-[#555A67] w-full"
                                dangerouslySetInnerHTML={{ __html: t("welcome.descriptions.part3") }}
                            ></span>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-4 justify-center w-full items-center mt-8">
                    <hr className="w-1/3" />
                    <Typography placeholder="" className="font-serif font-semibold text-center text-xl text-[#355379] my-4 md:my-0 px-6 md:px-4">
                        {t("welcome.preButton")}
                    </Typography>
                    <a
                        href="/work-together"
                        className="cursor-pointer bg-gradient-to-r from-primary-100 to-primary-200 hover:opacity-80 transition-all text-white font-bold py-2 px-8 rounded-full w-fit"
                    >
                        {t("welcome.button")}
                    </a>
                </div>
            </div>
            <div className="hidden md:flex md:w-1/3 justify-center items-center relative">
                <img src={WelcomePicture} className="max-h-[450px] relative z-20" />
                <img src={DecorationsWelcome} className="absolute -bottom-40 -left-16 z-10" />
            </div>
        </div>
    );
}
