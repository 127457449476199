import { useTranslation } from "react-i18next";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Audit from "./Audit/Audit";
import CardsServices from "./CardsServices/CardsServices";
import Customers from "./Customers/Customers";
import Headers from "./Header/Header";
import Invest from "./Invest/Invest";
import Method from "./Method/Method";
import PlanetMultiple from "./Planet/PlanetMultiple";
import Us from "./Us/Us";
import Welcome from "./Welcome/Welcome";

export default function App() {
    const { t } = useTranslation();
    return (
        <div className="w-full">
            <Navbar />
            <Headers />
            <Welcome />
            <div className="w-full  bg-gradient-to-r from-primary-100 to-primary-200 p-6 flex justify-center mt-12">
                <div className="max-w-[1280px] uppercase text-white text-xl tracking-widest text-center">
                    {t("brand.hr")}
                </div>
            </div>
            <CardsServices />
            <Audit />
            {/* <Planet /> */}
            <Invest />
            <PlanetMultiple />
            <Customers />
            {/* <Vision /> */}
            {/* <Achievements /> */}
            <Us />
            <Method />
            <Footer />
        </div>
    );
}
