import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BoxInvest from "./BoxInvest";
import BoxResponsive from "./BoxResponsive";

export default function Invest() {
    const { t } = useTranslation();
    const [listOfBoxesSelected, setListOfBoxesSelected] = useState<string[]>([]);

    useEffect(() => {
        const boxes = localStorage.getItem("selectedBoxes");
        if (boxes) {
            setListOfBoxesSelected(JSON.parse(boxes));
        }
    }, []);

    const handleSelectBox = (title: string) => {
        if (!listOfBoxesSelected) {
            setListOfBoxesSelected([title]);
            localStorage.setItem("selectedBoxes", JSON.stringify([title]));
            return;
        }
        if (listOfBoxesSelected.length === 0) {
            setListOfBoxesSelected([title]);
            localStorage.setItem("selectedBoxes", JSON.stringify([title]));
            return;
        }
        if (listOfBoxesSelected.includes(title)) {
            setListOfBoxesSelected(listOfBoxesSelected.filter((item) => item !== title));
            localStorage.setItem("selectedBoxes", JSON.stringify(listOfBoxesSelected.filter((item) => item !== title)));
        } else {
            setListOfBoxesSelected([...listOfBoxesSelected, title]);
            localStorage.setItem("selectedBoxes", JSON.stringify([...listOfBoxesSelected, title]));
        }
    };

    const redirectContact = () => {
        localStorage.setItem("selectedBoxes", JSON.stringify(listOfBoxesSelected));
        window.location.href = "/work-together";
    };

    return (
        <div className="flex flex-col py-12 items-center">
            <span className="uppercase tracking-[0.2rem] text-primary-100 text-xs md:text-sm px-4">{t("invest.preTitle")}</span>
            <h1 className="mb-8 uppercase text-center text-xl md:text-2xl text-[#355379] font-medium px-4" dangerouslySetInnerHTML={{ __html: t("invest.title") }}></h1>
            <div className="h-full w-full px-4 md:px-8 mt-4 max-w-[1280px]">
                <div className="bg-gradient-to-r from-primary-200 to-primary-100 rounded-2xl p-8 relative w-full  flex flex-col items-center">
                    <div className="absolute -top-6 left-0 right-0 w-fit mx-auto bg-white py-3 px-5 rounded-2xl" style={{ maxWidth: "calc(100% - 32px)"}}>
                        <h2 className="text-center font-normal text-black">{t("invest.question")}</h2>
                    </div>
                    <div className="hidden md:flex flex-wrap gap-4 justify-center pt-8 pb-2 md:py-4">
                        <BoxInvest handleSelectBox={handleSelectBox} selectedBoxes={listOfBoxesSelected} title={t("invest.keywords.customerReliationship")} icon="handshake" />
                        <BoxInvest handleSelectBox={handleSelectBox} selectedBoxes={listOfBoxesSelected} title={t("invest.keywords.dashboard")} icon="table-columns" />
                        <BoxInvest handleSelectBox={handleSelectBox} selectedBoxes={listOfBoxesSelected} title={t("invest.keywords.workflow")} icon="code-pull-request" />
                        <BoxInvest handleSelectBox={handleSelectBox} selectedBoxes={listOfBoxesSelected} title={t("invest.keywords.visio")} icon="headset" />
                        <BoxInvest handleSelectBox={handleSelectBox} selectedBoxes={listOfBoxesSelected} title={t("invest.keywords.delivery")} icon="truck" />
                        <BoxInvest handleSelectBox={handleSelectBox} selectedBoxes={listOfBoxesSelected} title={t("invest.keywords.documents")} icon="file" />
                        <BoxInvest handleSelectBox={handleSelectBox} selectedBoxes={listOfBoxesSelected} title={t("invest.keywords.payment")} icon="credit-card" />
                        <BoxInvest handleSelectBox={handleSelectBox} selectedBoxes={listOfBoxesSelected} title={t("invest.keywords.stocks")} icon="cubes" />
                        <BoxInvest handleSelectBox={handleSelectBox} selectedBoxes={listOfBoxesSelected} title={t("invest.keywords.ia")} icon="brain" />
                        <BoxInvest handleSelectBox={handleSelectBox} selectedBoxes={listOfBoxesSelected} title={t("invest.keywords.shop")} icon="cart-shopping" />
                        <BoxInvest handleSelectBox={handleSelectBox} selectedBoxes={listOfBoxesSelected} title={t("invest.keywords.meeting")} icon="calendar-day" />
                        <BoxInvest handleSelectBox={handleSelectBox} selectedBoxes={listOfBoxesSelected} title={t("invest.keywords.identification")} icon="fingerprint" />
                        <BoxInvest handleSelectBox={handleSelectBox} selectedBoxes={listOfBoxesSelected} title={t("invest.keywords.invoices")} icon="file-invoice-dollar" />
                        <BoxInvest handleSelectBox={handleSelectBox} selectedBoxes={listOfBoxesSelected} title={t("invest.keywords.conferences")} icon="microphone" />
                        <BoxInvest handleSelectBox={handleSelectBox} selectedBoxes={listOfBoxesSelected} title={t("invest.keywords.sells")} icon="barcode" />
                        <BoxInvest handleSelectBox={handleSelectBox} selectedBoxes={listOfBoxesSelected} title={t("invest.keywords.recruitment")} icon="user-plus" />
                    </div>
                    <div className="md:hidden">
                        <BoxResponsive listOfBoxes={listOfBoxesSelected} handleSelect={handleSelectBox} />
                    </div>
                    <span className="text-white text-xs ml-auto mb-4 md:mb-0">{t("invest.more")}</span>
                    <button onClick={() => redirectContact()} className="w-fit px-4 py-2 bg-white/100 rounded-full mx-auto hover:opacity-80 transition-all cursor-pointer">{t("invest.button")}</button>
                </div>
            </div>
        </div>
    );
}
