import { useTranslation } from "react-i18next";
import CustomersPicture from "../../../assets/img/Customers/customers.jpg";

import LogoLio from "../../../assets/img/StudyCases/Lio/logo.png";
import LogoEzoom from "../../../assets/img/Realizations/Ezoom/ezoom-logo.png";
import LogoEpitech from "../../../assets/img/StudyCases/Epitech/logo.png";

export default function Customers() {
    const { t } = useTranslation();

    return (
        // <div className="py-6 relative">
        //     <div className="relative max-w-[1280px] px-4 md:px-12 bg-white flex flex-col md:flex-row justify-center items-center w-full mx-auto grow">
        //         <div className="relative z-20 w-1/3 flex flex-col justify-center items-center grow h-64">
        //             <img src={CustomersPicture} alt="Customers" className="w-full rounded-l-2xl object-cover object-center h-full" />
        //         </div>
        //         <div className="relative z-20 w-2/3 flex flex-col bg-gray-50 grow py-8 px-4 md:px-12 h-64 justify-center">
        //             <span className="uppercase tracking-[0.2rem] text-primary-100 text-xs md:text-sm text-left">{t("customers.preTitle")}</span>
        //             <h1 className="uppercase text-xl md:text-2xl text-[#355379] font-medium text-left" dangerouslySetInnerHTML={{ __html: t("customers.title") }}></h1>
        //             <div className="flex gap-12 mt-6 items-center">
        //                 <img src={LogoLio} alt="Lio" className="h-12" />
        //                 <img src={LogoEzoom} alt="Ezoom" className="h-6" />
        //                 <img src={LogoEpitech} alt="Epitech" className="h-12" />
        //             </div>
        //         </div>
        //     </div>
        //     <div className="w-1/2 z-10 h-64 absolute top-6 right-0 bg-gray-50"></div>
        // </div>
        <div className="w-full py-12 relative flex justify-center items-center bg-gray-50">
            <div className="relative max-w-[1280px] px-4 md:px-12 flex flex-col justify-center w-full">
                <span className="uppercase tracking-[0.2rem] text-primary-100 text-xs md:text-sm text-center">{t("customers.preTitle")}</span>
                <h1
                    className="uppercase text-xl md:text-2xl text-[#355379] font-medium text-center"
                    dangerouslySetInnerHTML={{ __html: t("customers.title") }}
                ></h1>
                <div className="flex gap-12 mt-6 items-center justify-center">
                    <img src={LogoLio} alt="Lio" className="h-12" />
                    <img src={LogoEzoom} alt="Ezoom" className="h-6" />
                    <img src={LogoEpitech} alt="Epitech" className="h-12" />
                </div>
            </div>
        </div>
    );
}
