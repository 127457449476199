import { useTranslation } from "react-i18next";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/NavbarWithoutAnimation";

import EzoomHeader from "../../assets/img/StudyCases/Ezoom/headerPicture.png";
import EzoomLogo from "../../assets/img/StudyCases/Ezoom/logo.png";

import LioHeader from "../../assets/img/StudyCases/Lio/headerPicture.png";
import LioLogo from "../../assets/img/StudyCases/Lio/logo.png";

import AsIonisLyonHeader from "../../assets/img/StudyCases/AsIonisLyon/headerPicture.jpg";
import AsIonisLyonLogo from "../../assets/img/StudyCases/AsIonisLyon/logo.png";

import LatooHeader from "../../assets/img/StudyCases/Latoo/headerPicture.jpg";
import LatooLogo from "../../assets/img/StudyCases/Latoo/logoOutline.png";

import YogaHeader from "../../assets/img/StudyCases/Yoga/headerPicture.png";
import YogaLogo from "../../assets/img/StudyCases/Yoga/logo.png";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@material-tailwind/react";
import AmbitiousProject from "../../components/StudyCase/AmbitiousProject";

const datas = [
    {
        id: "ezoom",
        title: "Ezoom",
        backgroundPicture: EzoomHeader,
        logoPicture: EzoomLogo,
        particule: "ezoom",
        link: "/etudes-de-cas/ezoom",
    },
    {
        id: "lio",
        title: "Lio Modélisation",
        backgroundPicture: LioHeader,
        logoPicture: LioLogo,
        particule: "lio",
        link: "/etudes-de-cas/lio",
    },
    {
        id: "as-ionis-lyon",
        title: "AS Ionis Lyon",
        backgroundPicture: AsIonisLyonHeader,
        logoPicture: AsIonisLyonLogo,
        particule: "asionislyon",
        link: "/etudes-de-cas/as-ionis-lyon",
    },
    {
        id: "studio-yoga",
        title: "Studio de Yoga",
        backgroundPicture: YogaHeader,
        logoPicture: YogaLogo,
        particule: "yoga",
        link: "/etudes-de-cas/studio-yoga",
    },
    {
        id: "latoo",
        title: "Latoo",
        backgroundPicture: LatooHeader,
        logoPicture: LatooLogo,
        particule: "latoo",
        link: "/etudes-de-cas/latoo",
    },
];

export default function AnnuaireEtudeDeCas() {
    const [isHover, setIsHover] = useState<boolean[]>([false, false, false, false, false]);
    const { t } = useTranslation();

    const isEnter = (index: number) => {
        const newIsHover = isHover.map((_, i) => i === index);
        setIsHover(newIsHover);
    };

    const isLeave = () => {
        setIsHover([false, false, false, false, false]);
    };

    return (
        <div className="w-full bg-white">
            <Navbar />
            <div className="absolute z-0 w-full h-64 top-0 left-0  bg-gradient-to-r from-primary-100 to-primary-200 rounded-b-[50px]"></div>
            <div className="relative z-10 w-full flex flex-col px-4 md:px-16 pt-24 mt-12 mb-16 mx-auto max-w-[1408px]">
                {/* <span className="uppercase tracking-[0.2rem] text-white text-xs md:text-sm text-center px-4">{t("studyCase.registry.preTitle")}</span>
                <h1 className="uppercase text-white text-xl md:text-2xl text-center px-4">{t("studyCase.registry.title")}</h1> */}
                <div className="mt-4 mb-mt-0 w-full flex flex-col items-center gap-4 bg-white rounded-[50px] lg:mt-4 pt-4 md:py-12 px-4 md:px-16 pb-4 font-serif text-justify text-sm text-black">
                    <div className="flex flex-col w-full">
                        <span className="uppercase tracking-[0.2rem] text-primary-100 text-xs md:text-sm text-left">
                            {t("studyCase.registry.preTitle")}
                        </span>
                        <h1
                            className="mb-1 uppercase font-medium text-[#355379] text-xl md:text-2xl text-left"
                            dangerouslySetInnerHTML={{ __html: t("studyCase.registry.title") }}
                        ></h1>
                        <div className="mb-6 text-[#355379] text-sm md:text-base text-left" dangerouslySetInnerHTML={{ __html: t("studyCase.registry.description") }}></div>
                    </div>

                    <div className="activity-container">
                        <a
                            href="/etudes-de-cas/ezoom"
                            onMouseEnter={() => isEnter(0)}
                            onMouseLeave={() => isLeave()}
                            className="image-container img-one overflow-hidden"
                        >
                            <img
                                src={EzoomHeader}
                                alt="ezoom"
                                className={`w-full h-full object-cover rounded-xl  ${isHover[0] ? "scale-150" : "scale-100"} transition-all`}
                            />
                            <div className="absolute top-0 left-0 h-full w-full rounded-xl flex flex-col gap-2 justify-center items-center bg-black/30">
                                {/* <div className="flex justify-center items-center bg-gradient-to-br from-primary-100 to-primary-200 w-fit p-1 rounded-lg">
                                    <div className="w-full h-10 bg-white p-2 rounded-md">
                                        <img src={EzoomLogo} alt="logo ezoom" className="h-full" />
                                    </div>
                                </div>
                                <p className="uppercase text-base bg-white rounded-md px-2 py-1 font-bold tracking-wider font-poppins text-primary-100">
                                    Ezoom
                                </p> */}
                                <p className={`uppercase text-base h-10 bg-white rounded-t-md px-2 py-1 font-bold tracking-wider font-poppins text-primary-100 ${isHover[0] ? "opacity-0" : "opacity-100"} transition-all`}>
                                    <img src={EzoomLogo} alt="logo studio de yoga" className="h-full" />
                                </p>
                                <p className={`-mt-2 uppercase text-base bg-white rounded-md px-2 py-1 font-bold tracking-wider font-poppins text-primary-100 ${isHover[0] ? "opacity-0" : "opacity-100"} transition-all`}>
                                    Ezoom
                                </p>
                            </div>
                            <div
                                className={`absolute top-0 left-0 h-full w-full rounded-xl flex flex-col text-left gap-2 justify-end items-start p-4 uppercase font-poppins font-bold -tracking-wider bg-black/50 ${
                                    isHover[0] ? "opacity-100" : "opacity-0"
                                } transition-all text-white`}
                            >
                                <div className="flex gap-2">
                                    <Tooltip placement="top" content="Responsive" color="lightBlue" className="font-poppins bg-primary-100">
                                        <div className="h-10 w-10 flex items-center justify-center bg-white rounded-full">
                                            <FontAwesomeIcon icon="mobile" className="text-primary-100" />
                                        </div>
                                    </Tooltip>
                                    <Tooltip placement="top" content="Application web" color="lightBlue" className="font-poppins bg-primary-100">
                                        <div className="h-10 w-10 flex items-center justify-center bg-white rounded-full">
                                            <FontAwesomeIcon icon="laptop" className="text-primary-100" />
                                        </div>
                                    </Tooltip>
                                    <Tooltip placement="top" content="Algorithme avancé" color="lightBlue" className="font-poppins bg-primary-100">
                                        <div className="h-10 w-10 flex items-center justify-center bg-white rounded-full">
                                            <FontAwesomeIcon icon="circle-nodes" className="text-primary-100" />
                                        </div>
                                    </Tooltip>
                                    <Tooltip placement="top" content="Paiement en ligne" color="lightBlue" className="font-poppins bg-primary-100">
                                        <div className="h-10 w-10 flex items-center justify-center bg-white rounded-full">
                                            <FontAwesomeIcon icon="credit-card" className="text-primary-100" />
                                        </div>
                                    </Tooltip>
                                </div>
                                <span>Achat de photo ou de prestation</span>
                            </div>
                        </a>

                        <a
                            href="/etudes-de-cas/lio"
                            onMouseEnter={() => isEnter(1)}
                            onMouseLeave={() => isLeave()}
                            className="image-container img-three overflow-hidden"
                        >
                            <img src={LioHeader} alt="lio" className={`w-full h-full object-cover ${isHover[1] ? "scale-150" : "scale-100"} transition-all`} />
                            <div className="absolute top-0 left-0 h-full w-full rounded-xl flex flex-col gap-2 justify-center items-center bg-black/30">
                                {/* <div className="flex justify-center items-center bg-gradient-to-br from-primary-100 to-primary-200 w-fit p-1 rounded-lg">
                                    <div className="w-full h-10 bg-white p-2 rounded-md">
                                        <img src={LioLogo} alt="logo Lio Modélisation" className="h-full" />
                                    </div>
                                </div>
                                <p className="uppercase text-base bg-white rounded-md px-2 py-1 font-bold tracking-wider font-poppins text-primary-100">
                                    Lio Modélisation
                                </p> */}
                                <p className={`uppercase text-base h-10 bg-white rounded-t-md px-2 py-1 font-bold tracking-wider font-poppins text-primary-100 ${isHover[1] ? "opacity-0" : "opacity-100"} transition-all`}>
                                    <img src={LioLogo} alt="logo studio de yoga" className="h-full" />
                                </p>
                                <p className={`-mt-2 uppercase text-base bg-white rounded-md px-2 py-1 font-bold tracking-wider font-poppins text-primary-100 ${isHover[1] ? "opacity-0" : "opacity-100"} transition-all`}>
                                    Lio Modélisation
                                </p>
                            </div>
                            <div
                                className={`absolute top-0 left-0 h-full w-full rounded-xl flex flex-col text-left gap-2 justify-end items-start p-4 uppercase font-poppins font-bold -tracking-wider bg-black/50 ${
                                    isHover[1] ? "opacity-100" : "opacity-0"
                                } transition-all text-white`}
                            >
                                <div className="flex gap-2">
                                    <Tooltip placement="top" content="Responsive" color="lightBlue" className="font-poppins bg-primary-100">
                                        <div className="h-10 w-10 flex items-center justify-center bg-white rounded-full">
                                            <FontAwesomeIcon icon="mobile" className="text-primary-100" />
                                        </div>
                                    </Tooltip>
                                    <Tooltip placement="top" content="Application web" color="lightBlue" className="font-poppins bg-primary-100">
                                        <div className="h-10 w-10 flex items-center justify-center bg-white rounded-full">
                                            <FontAwesomeIcon icon="laptop" className="text-primary-100" />
                                        </div>
                                    </Tooltip>
                                    <Tooltip placement="top" content="Logiciel métier" color="lightBlue" className="font-poppins bg-primary-100">
                                        <div className="h-10 w-10 flex items-center justify-center bg-white rounded-full">
                                            <FontAwesomeIcon icon="computer" className="text-primary-100" />
                                        </div>
                                    </Tooltip>
                                    <Tooltip placement="top" content="Algorithme avancé" color="lightBlue" className="font-poppins bg-primary-100">
                                        <div className="h-10 w-10 flex items-center justify-center bg-white rounded-full">
                                            <FontAwesomeIcon icon="circle-nodes" className="text-primary-100" />
                                        </div>
                                    </Tooltip>
                                    <Tooltip placement="top" content="Intelligence Artificielle" color="lightBlue" className="font-poppins bg-primary-100">
                                        <div className="h-10 w-10 flex items-center justify-center bg-white rounded-full">
                                            <FontAwesomeIcon icon="robot" className="text-primary-100" />
                                        </div>
                                    </Tooltip>
                                    <Tooltip placement="top" content="Paiement en ligne" color="lightBlue" className="font-poppins bg-primary-100">
                                        <div className="h-10 w-10 flex items-center justify-center bg-white rounded-full">
                                            <FontAwesomeIcon icon="credit-card" className="text-primary-100" />
                                        </div>
                                    </Tooltip>
                                </div>
                                <span>Gestion du laboratoire</span>
                            </div>
                        </a>

                        <a
                            href="/etudes-de-cas/as-ionis-lyon"
                            onMouseEnter={() => isEnter(2)}
                            onMouseLeave={() => isLeave()}
                            className="image-container img-two overflow-hidden"
                        >
                            <img
                                src={AsIonisLyonHeader}
                                alt="as ionis lyon"
                                className={`w-full h-full object-cover rounded-xl ${isHover[2] ? "scale-150" : "scale-100"} transition-all`}
                            />
                            <div className="absolute top-0 left-0 h-full w-full rounded-xl flex flex-col gap-2 justify-center items-center bg-black/30">
                                {/* <div className="flex justify-center items-center bg-gradient-to-br from-primary-100 to-primary-200 w-fit p-1 rounded-lg">
                                    <div className="w-full h-10 bg-white p-2 rounded-md">
                                        <img src={AsIonisLyonLogo} alt="logo AS Ionis Lyon" className="h-full" />
                                    </div>
                                </div>
                                <p className="uppercase text-base bg-white rounded-md px-2 py-1 font-bold tracking-wider font-poppins text-primary-100">
                                    AS Ionis Lyon
                                </p> */}
                                <p className={`uppercase text-base h-10 bg-white rounded-t-md px-2 py-1 font-bold tracking-wider font-poppins text-primary-100 ${isHover[2] ? "opacity-0" : "opacity-100"} transition-all`}>
                                    <img src={AsIonisLyonLogo} alt="logo studio de yoga" className="h-full" />
                                </p>
                                <p className={`-mt-2 uppercase text-base bg-white rounded-md px-2 py-1 font-bold tracking-wider font-poppins text-primary-100 ${isHover[2] ? "opacity-0" : "opacity-100"} transition-all`}>
                                    AS Ionis Lyon
                                </p>
                            </div>
                            <div
                                className={`absolute top-0 left-0 h-full w-full rounded-xl flex flex-col text-left gap-2 justify-end items-start p-4 uppercase font-poppins font-bold -tracking-wider bg-black/50 ${
                                    isHover[2] ? "opacity-100" : "opacity-0"
                                } transition-all text-white`}
                            >
                                <div className="flex gap-2">
                                    <Tooltip placement="top" content="Application mobile" color="lightBlue" className="font-poppins bg-primary-100">
                                        <div className="h-10 w-10 flex items-center justify-center bg-white rounded-full">
                                            <FontAwesomeIcon icon="mobile-alt" className="text-primary-100" />
                                        </div>
                                    </Tooltip>
                                    <Tooltip placement="top" content="Application web" color="lightBlue" className="font-poppins bg-primary-100">
                                        <div className="h-10 w-10 flex items-center justify-center bg-white rounded-full">
                                            <FontAwesomeIcon icon="laptop" className="text-primary-100" />
                                        </div>
                                    </Tooltip>
                                </div>
                                <span>Gestion de l’association</span>
                            </div>
                        </a>

                        <a
                            href="/etudes-de-cas/studio-yoga"
                            onMouseEnter={() => isEnter(3)}
                            onMouseLeave={() => isLeave()}
                            className="image-container img-four overflow-hidden"
                        >
                            <img
                                src={YogaHeader}
                                alt="yoga"
                                className={`w-full h-full object-cover rounded-xl ${isHover[3] ? "scale-150" : "scale-100"} transition-all`}
                            />
                            <div className="absolute top-0 left-0 h-full w-full rounded-xl flex flex-col gap-2 justify-center items-center bg-black/30">
                                {/* <div className="flex justify-center items-center bg-gradient-to-br from-primary-100 to-primary-200 w-fit p-1 rounded-lg">
                                    <div className="w-full h-10 bg-white p-2 rounded-md">
                                        <img src={YogaLogo} alt="logo studio de yoga" className="h-full" />
                                    </div>
                                </div> */}
                                <p className={`uppercase text-base h-10 bg-white rounded-t-md px-2 py-1 font-bold tracking-wider font-poppins text-primary-100 ${isHover[3] ? "opacity-0" : "opacity-100"} transition-all`}>
                                    <img src={YogaLogo} alt="logo studio de yoga" className="h-full" />
                                </p>
                                <p className={`-mt-2 uppercase text-base bg-white rounded-md px-2 py-1 font-bold tracking-wider font-poppins text-primary-100 ${isHover[3] ? "opacity-0" : "opacity-100"} transition-all`}>
                                    Studio de Yoga
                                </p>
                                {/* <div className="flex gap-2 absolute bottom-2 left-2">
                                    <p className="uppercase text-xs bg-primary-100 rounded-md px-2 py-1 font-bold tracking-wider font-poppins text-white">
                                        Web
                                    </p>
                                    <p className="uppercase text-xs bg-primary-100 rounded-md px-2 py-1 font-bold tracking-wider font-poppins text-white">
                                        Blop
                                    </p>
                                </div> */}
                            </div>
                            <div
                                className={`absolute top-0 left-0 h-full w-full rounded-xl flex flex-col text-left gap-2 justify-end items-start p-4 uppercase font-poppins font-bold -tracking-wider bg-black/50 ${
                                    isHover[3] ? "opacity-100" : "opacity-0"
                                } transition-all text-white`}
                            >
                                <div className="flex gap-2">
                                    <Tooltip placement="top" content="Application web" color="lightBlue" className="font-poppins bg-primary-100">
                                        <div className="h-10 w-10 flex items-center justify-center bg-white rounded-full">
                                            <FontAwesomeIcon icon="laptop" className="text-primary-100" />
                                        </div>
                                    </Tooltip>
                                </div>
                                <span>Cours de Yoga en ligne</span>
                            </div>
                        </a>

                        <a
                            href="/etudes-de-cas/latoo"
                            onMouseEnter={() => isEnter(4)}
                            onMouseLeave={() => isLeave()}
                            className="image-container img-five overflow-hidden"
                        >
                            <img
                                src={LatooHeader}
                                alt="hiking"
                                className={`w-full h-full object-cover rounded-xl ${isHover[4] ? "scale-150" : "scale-100"} transition-all`}
                            />
                            <div className="absolute top-0 left-0 h-full w-full rounded-xl flex flex-col gap-2 justify-center items-center bg-black/30">
                                {/* <div className={`flex justify-center items-center bg-gradient-to-br from-primary-100 to-primary-200 w-fit p-1 rounded-lg`}>
                                    <div className="w-full h-10 bg-white p-2 rounded-md">
                                        <img src={LatooLogo} alt="logo latoo" className="h-full" />
                                    </div>
                                </div>
                                <p className={`uppercase text-base bg-white rounded-md px-2 py-1 font-bold tracking-wider font-poppins text-primary-100`}>
                                    Latoo
                                </p> */}
                                <p className={`uppercase text-base h-10 bg-white rounded-md px-2 py-1 font-bold tracking-wider font-poppins text-primary-100 ${isHover[4] ? "opacity-0" : "opacity-100"} transition-all`}>
                                    <img src={LatooLogo} alt="logo latoo" className="h-full" />
                                </p>
                                <p className={`-mt-2 uppercase text-base bg-white rounded-b-md px-2 py-1 font-bold tracking-wider font-poppins text-primary-100 ${isHover[4] ? "opacity-0" : "opacity-100"} transition-all`}>
                                    Latoo
                                </p>
                            </div>
                            {/* <div className={`absolute top-0 left-0 h-full w-full rounded-xl flex flex-col text-left gap-2 justify-end items-start p-4 uppercase font-poppins font-bold -tracking-wider bg-black/50 ${isHover ? "opacity-100" : "opacity-0"} transition-all text-white`} dangerouslySetInnerHTML={{ __html: t("studyCase.registry.latoo") }}>
                            </div> */}
                            <div
                                className={`absolute top-0 left-0 h-full w-full rounded-xl flex flex-col text-left gap-2 justify-end items-start p-4 uppercase font-poppins font-bold -tracking-wider bg-black/50 ${
                                    isHover[4] ? "opacity-100" : "opacity-0"
                                } transition-all text-white`}
                            >
                                <div className="flex gap-2">
                                    <Tooltip placement="top" content="Application mobile" color="lightBlue" className="font-poppins bg-primary-100">
                                        <div className="h-10 w-10 flex items-center justify-center bg-white rounded-full">
                                            <FontAwesomeIcon icon="mobile-alt" className="text-primary-100" />
                                        </div>
                                    </Tooltip>
                                </div>
                                <span>Annuaire évènementiel étudiant</span>
                            </div>
                        </a>

                        {/* <a href="" className="image-container img-six">
                            <img
                                src="https://github.com/ecemgo/mini-samples-great-tricks/assets/13468728/7568e0ff-edb5-43dd-bff5-aed405fc32d9"
                                alt="swimming"
                            />
                            <div className="absolute inset-0 flex flex-col items-end justify-end bg-gradient-to-b from-transparent to-black/25 rounded-lg transition-opacity duration-500">
                                <h3 className="mb-2 mr-2 text-white">Swimming</h3>
                            </div>
                        </a> */}
                    </div>
                </div>
                {/* <div className="mt-4 mb-mt-0 w-full flex flex-col items-center gap-4 bg-white rounded-lg lg:mt-4 pt-4 md:py-12 px-4 md:px-16 pb-4 font-serif text-justify text-sm text-black">
                    <div className="flex flex-col gap-2">
                        <span className="uppercase tracking-[0.2rem] text-primary-100 text-xs md:text-sm text-left  w-full">{t("studyCase.registry.preTitle")}</span>
                        <h1 className="uppercase font-medium text-[#355379] text-xl md:text-2xl text-left w-full" dangerouslySetInnerHTML={{ __html: t("studyCase.registry.title") }}></h1>
                        <div className="leading-6 text-base mb-8 text-justify">{t("studyCase.registry.description")}</div>
                    </div>
                    <div className="flex justify-around flex-wrap gap-4">
                        {datas.map((data) => (
                            <Link to={data.link} className="w-[30%] relative bg-gradient-to-br from-primary-100 to-primary-100 rounded-2xl p-1" key={data.id}>
                                <div className="h-56 2xl:h-64 relative z-0">
                                    <img src={data.backgroundPicture} alt={data.title} className="w-full h-full object-cover rounded-t-xl" />
                                    <div className="absolute bottom-6 left-1/2 -translate-x-1/2 flex justify-center items-center bg-gradient-to-br from-primary-100 to-primary-200 w-fit p-1 rounded-lg">
                                        <div className="w-full h-10 bg-white p-2 rounded-md">
                                            <img src={data.logoPicture} alt={`logo ${data.title}`} className="h-full" />
                                        </div>
                                    </div>
                                </div>
                                <div className="relative z-10 -mt-1 flex justify-center items-center w-full text-center rounded-b-2xl">
                                    <div className="absolute -top-4 h-8 bg-primary-200 text-white rounded-full flex justify-center items-center px-4">
                                        {data.title}
                                    </div>
                                    <div className="w-full h-full bg-white p-2 rounded-b-xl text-left pt-5">
                                        <span className="font-normal" dangerouslySetInnerHTML={{ __html: t(`studyCase.registry.${data.particule}`)}}></span>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div> */}
            </div>
            <AmbitiousProject />
            <Footer />
        </div>
    );
}
