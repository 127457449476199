import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { motion, useScroll, useTransform } from "framer-motion";

const CustomTimeline = () => {
    const { t } = useTranslation();

    const ref1 = useRef<SVGSVGElement>(null);
    const ref2 = useRef<SVGSVGElement>(null);
    const ref3 = useRef<SVGSVGElement>(null);
    const ref4 = useRef<SVGSVGElement>(null);
    const ref5 = useRef<SVGSVGElement>(null);

    const [distances, setDistances] = useState<{ start: number; end: number }[]>([
        {
            start: 0,
            end: 0,
        },
        {
            start: 0,
            end: 0,
        },
        {
            start: 0,
            end: 0,
        },
        {
            start: 0,
            end: 0,
        },
        {
            start: 0,
            end: 0,
        },
    ]);

    const { scrollY } = useScroll();

    useEffect(() => {
        const handleResize = () => {
            const halfHeightPage = window.innerHeight / 3;
            if (!ref1.current || !ref2.current || !ref3.current || !ref4.current || !ref5.current) return;

            const beginRef1 = ref1.current.getBoundingClientRect().top + window.scrollY - halfHeightPage * 2;
            const endRef1 = ref1.current.getBoundingClientRect().bottom + window.scrollY - halfHeightPage * 2;
            const beginRef2 = ref2.current.getBoundingClientRect().top + window.scrollY - halfHeightPage * 2;
            const endRef2 = ref2.current.getBoundingClientRect().bottom + window.scrollY - halfHeightPage * 2;
            const beginRef3 = ref3.current.getBoundingClientRect().top + window.scrollY - halfHeightPage * 2;
            const endRef3 = ref3.current.getBoundingClientRect().bottom + window.scrollY - halfHeightPage * 2;
            const beginRef4 = ref4.current.getBoundingClientRect().top + window.scrollY - halfHeightPage * 2;
            const endRef4 = ref4.current.getBoundingClientRect().bottom + window.scrollY - halfHeightPage * 2;
            const beginRef5 = ref5.current.getBoundingClientRect().top + window.scrollY - halfHeightPage * 2;
            const endRef5 = ref5.current.getBoundingClientRect().bottom + window.scrollY - halfHeightPage * 2;

            setDistances([
                { start: beginRef1, end: endRef1 },
                { start: beginRef2, end: endRef2 },
                { start: beginRef3, end: endRef3 },
                { start: beginRef4, end: endRef4 },
                { start: beginRef5, end: endRef5 },
            ]);
        };
        window.addEventListener("scroll", handleResize);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("scroll", handleResize);
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const opacity1 = useTransform(scrollY, [distances[0].start, distances[0].end], ["25", "100"]);
    const opacity2 = useTransform(scrollY, [distances[1].start, distances[1].end], ["25", "100"]);
    const opacity3 = useTransform(scrollY, [distances[2].start, distances[2].end], ["25", "100"]);
    const opacity4 = useTransform(scrollY, [distances[3].start, distances[3].end], ["25", "100"]);
    const opacity5 = useTransform(scrollY, [distances[4].start, distances[4].end], ["25", "100"]);

    const color1 = useTransform(scrollY, [distances[0].start - 50, distances[0].end - 5], ["#e2e2e2", "#029BDD"]);
    const color2 = useTransform(scrollY, [distances[1].start - 50, distances[1].end - 5], ["#e2e2e2", "#029BDD"]);
    const color3 = useTransform(scrollY, [distances[2].start - 50, distances[2].end - 5], ["#e2e2e2", "#029BDD"]);
    const color4 = useTransform(scrollY, [distances[3].start - 50, distances[3].end - 5], ["#e2e2e2", "#029BDD"]);
    const color5 = useTransform(scrollY, [distances[4].start - 50, distances[4].end - 5], ["#e2e2e2", "#029BDD"]);

    const scale1 = useTransform(scrollY, [distances[0].start - 50, distances[0].end - 5], [0.95, 1]);
    const scale2 = useTransform(scrollY, [distances[1].start - 50, distances[1].end - 5], [0.95, 1]);
    const scale3 = useTransform(scrollY, [distances[2].start - 50, distances[2].end - 5], [0.95, 1]);
    const scale4 = useTransform(scrollY, [distances[3].start - 50, distances[3].end - 5], [0.95, 1]);
    const scale5 = useTransform(scrollY, [distances[4].start - 50, distances[4].end - 5], [0.95, 1]);

    const bold1 = useTransform(scrollY, [distances[0].start - 50, distances[0].end - 5], [300, 600]);
    const bold2 = useTransform(scrollY, [distances[1].start - 50, distances[1].end - 5], [300, 600]);
    const bold3 = useTransform(scrollY, [distances[2].start - 50, distances[2].end - 5], [300, 600]);
    const bold4 = useTransform(scrollY, [distances[3].start - 50, distances[3].end - 5], [300, 600]);
    const bold5 = useTransform(scrollY, [distances[4].start - 50, distances[4].end - 5], [300, 600]);

    const colorParagraph1 = useTransform(scrollY, [distances[0].start, distances[0].end], ["#e2e2e2", "#838383"]);
    const colorParagraph2 = useTransform(scrollY, [distances[1].start, distances[1].end], ["#e2e2e2", "#838383"]);
    const colorParagraph3 = useTransform(scrollY, [distances[2].start, distances[2].end], ["#e2e2e2", "#838383"]);
    const colorParagraph4 = useTransform(scrollY, [distances[3].start, distances[3].end], ["#e2e2e2", "#838383"]);
    const colorParagraph5 = useTransform(scrollY, [distances[4].start, distances[4].end], ["#e2e2e2", "#838383"]);

    const colorPreText1 = useTransform(scrollY, [distances[0].start, distances[0].end], ["#029bdd33", "#029bdd"]);
    const colorPreText2 = useTransform(scrollY, [distances[1].start, distances[1].end], ["#029bdd33", "#029bdd"]);
    const colorPreText3 = useTransform(scrollY, [distances[2].start, distances[2].end], ["#029bdd33", "#029bdd"]);
    const colorPreText4 = useTransform(scrollY, [distances[3].start, distances[3].end], ["#029bdd33", "#029bdd"]);
    const colorPreText5 = useTransform(scrollY, [distances[4].start, distances[4].end], ["#029bdd33", "#029bdd"]);

    const colorTitle1 = useTransform(scrollY, [distances[0].start, distances[0].end], ["#35537933", "#355379"]);
    const colorTitle2 = useTransform(scrollY, [distances[1].start, distances[1].end], ["#35537933", "#355379"]);
    const colorTitle3 = useTransform(scrollY, [distances[2].start, distances[2].end], ["#35537933", "#355379"]);
    const colorTitle4 = useTransform(scrollY, [distances[3].start, distances[3].end], ["#35537933", "#355379"]);
    const colorTitle5 = useTransform(scrollY, [distances[4].start, distances[4].end], ["#35537933", "#355379"]);

    return (
        <div className="flex w-full relative py-36 min-h-screen flex-col justify-center items-center bg-white" style={{ zIndex: -1 }}>
            <div className="absolute top-0 left-0 right-0 bg-gradient-to-b from-white to-transparent h-[8rem] z-20 pointer-events-none"></div>
            <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-white to-transparent h-[8rem] z-20 pointer-events-none"></div>

            <div
                className="w-[.25rem] bg-[#e2e2e2] pointer-events-none absolute -top-[105vh] left-[8px] md:left-auto mr-auto ml-0 md:ml-auto"
                style={{ height: "calc(100% + 105vh)" }}
            >
                <div className="block opacity-100 will-change-[opacity] z-10 w-[.25rem] h-[66vh] bg-gradient-to-b from-white to-primary-100 sticky top-0 bottom-[50vh] md:bottom-auto mr-[.08rem] md:mr-auto"></div>
            </div>

            <div
                className="item-1 py-[3.5rem] z-20 flex flex-col grid-col md:grid relative"
                style={{ gridColumnGap: "4rem", gridRowGap: "0rem", gridTemplateColumns: "1fr .01fr 1fr", gridTemplateRows: "auto", gridAutoColumns: "1fr" }}
            >
                <div className="text-left md:text-right sm:ml-[200px] md:ml-0 ml-[60px]" style={{ gridArea: "span 1 / span 1 / span 1 / span 1" }}>
                    <motion.h3
                        className={`will-change-[opacity]  text-[30px] md:text-[26px] lg:text-[1.8rem] mt-0 lg:-mt-3 origin-left md:origin-right`}
                        style={{ color: color1, opacity: opacity1, scale: scale1, fontWeight: bold1 }}
                    >
                        {t("method.boxes.advice.title")}
                    </motion.h3>
                </div>
                <div
                    className="self-start w-[1.5rem] h-[1.5rem] pointer-events-none color-[#A1A1A1] justify-center items-stretch flex"
                    style={{ gridArea: "span 1 / span 1 / span 1 / span 1" }}
                >
                    <motion.svg
                        ref={ref1}
                        className="text-[#e2e2e2] w-[1.25rem] absolute top-[25%] bottom-auto left-0 right-[0%] md:right-auto md:left-auto md:top-auto md:bottom-auto md:static md:w-[1.5rem]"
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        viewBox="0 0 24 25"
                        fill="none"
                        style={{ opacity: opacity1, color: color1 }}
                    >
                        <circle cx="12" cy="12.6074" r="10" fill="white" stroke="currentcolor" strokeWidth="4"></circle>
                    </motion.svg>
                </div>
                <div className="ml-[60px] sm:ml-[200px] md:ml-auto sm:max-w-[450px] md:max-w-[500px] lg:max-w-[550px] xl:max-w-[600px] 2xl:max-w-[600px]" style={{ gridArea: "span 1 / span 1 / span 1 / span 1" }}>
                    <motion.div className="w-full uppercase tracking-[0.2rem] text-xs bg-white" style={{ opacity: opacity1, color: colorPreText1 }}>
                        {t("method.boxes.advice.preTitle")}
                    </motion.div>
                    <motion.h1
                        className="w-full uppercase text-lg font-medium bg-white"
                        dangerouslySetInnerHTML={{ __html: t("method.boxes.advice.bigTitle") }}
                        style={{ color: colorTitle1 }}
                    ></motion.h1>
                    <motion.p
                        style={{ color: colorParagraph1, opacity: opacity1 }}
                        className="leading-5 text-xs md:text-[14px] 2xl:text-[16px]"
                        dangerouslySetInnerHTML={{ __html: t("method.boxes.advice.description") }}
                    ></motion.p>
                </div>
            </div>
            <div
                className="item-2 py-[3.5rem] z-20 flex flex-col grid-col md:grid relative "
                style={{ gridColumnGap: "4rem", gridRowGap: "0rem", gridTemplateColumns: "1fr .01fr 1fr", gridTemplateRows: "auto", gridAutoColumns: "1fr" }}
            >
                <div className="text-left md:text-right sm:ml-[200px] md:ml-0 ml-[60px]" style={{ gridArea: "span 1 / span 1 / span 1 / span 1" }}>
                    <motion.h3
                        className={`will-change-[opacity]  text-[30px] md:text-[26px] lg:text-[1.8rem] mt-0 lg:-mt-3 origin-left md:origin-right`}
                        style={{ color: color2, opacity: opacity2, scale: scale2, fontWeight: bold2 }}
                    >
                        {t("method.boxes.architecture.title")}
                    </motion.h3>
                </div>
                <div
                    className="self-start w-[1.5rem] h-[1.5rem] pointer-events-none color-[#A1A1A1] justify-center items-stretch flex"
                    style={{ gridArea: "span 1 / span 1 / span 1 / span 1" }}
                >
                    <motion.svg
                        ref={ref2}
                        className="text-[#e2e2e2] w-[1.25rem] absolute top-[26%] bottom-auto left-0 right-[0%] md:right-auto md:left-auto md:top-auto md:bottom-auto md:static md:w-[1.5rem]"
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        viewBox="0 0 24 25"
                        fill="none"
                        style={{ opacity: opacity2, color: color2 }}
                    >
                        <circle cx="12" cy="12.6074" r="10" fill="white" stroke="currentcolor" strokeWidth="4"></circle>
                    </motion.svg>
                </div>
                <div className="ml-[60px] sm:ml-[200px] md:ml-auto sm:max-w-[450px] md:max-w-[500px] lg:max-w-[550px] xl:max-w-[600px] 2xl:max-w-[600px]" style={{ gridArea: "span 1 / span 1 / span 1 / span 1" }}>
                    <motion.div className="w-full uppercase tracking-[0.2rem] text-xs bg-white" style={{ opacity: opacity2, color: colorPreText2 }}>
                        {t("method.boxes.architecture.preTitle")}
                    </motion.div>
                    <motion.h1
                        className="w-full uppercase text-lg text-[#355379] font-medium bg-white "
                        dangerouslySetInnerHTML={{ __html: t("method.boxes.architecture.bigTitle") }}
                        style={{ color: colorTitle2 }}
                    ></motion.h1>
                    <motion.p
                        style={{ color: colorParagraph2, opacity: opacity2 }}
                        className="leading-5 text-xs md:text-[14px] 2xl:text-[16px]"
                        dangerouslySetInnerHTML={{ __html: t("method.boxes.architecture.description") }}
                    ></motion.p>
                </div>
            </div>
            <div
                className="item-2 py-[3.5rem] z-20 flex flex-col grid-col md:grid relative "
                style={{ gridColumnGap: "4rem", gridRowGap: "0rem", gridTemplateColumns: "1fr .01fr 1fr", gridTemplateRows: "auto", gridAutoColumns: "1fr" }}
            >
                <div className="text-left md:text-right sm:ml-[200px] md:ml-0 ml-[60px]" style={{ gridArea: "span 1 / span 1 / span 1 / span 1" }}>
                    <motion.h3
                        className={`will-change-[opacity] text-[30px] md:text-[26px] lg:text-[1.8rem] mt-0 lg:-mt-3 origin-left md:origin-right`}
                        style={{ color: color3, opacity: opacity3, scale: scale3, fontWeight: bold3 }}
                    >
                        {t("method.boxes.maquette.title")}
                    </motion.h3>
                </div>
                <div
                    className="self-start w-[1.5rem] h-[1.5rem] pointer-events-none color-[#A1A1A1] justify-center items-stretch flex"
                    style={{ gridArea: "span 1 / span 1 / span 1 / span 1" }}
                >
                    <motion.svg
                        ref={ref3}
                        className="text-[#e2e2e2] w-[1.25rem] absolute top-[26%] bottom-auto left-0 right-[0%] md:right-auto md:left-auto md:top-auto md:bottom-auto md:static md:w-[1.5rem]"
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        viewBox="0 0 24 25"
                        fill="none"
                        style={{ opacity: opacity3, color: color3 }}
                    >
                        <circle cx="12" cy="12.6074" r="10" fill="white" stroke="currentcolor" strokeWidth="4"></circle>
                    </motion.svg>
                </div>
                <div className="ml-[20px] sm:ml-[200px] md:ml-auto sm:max-w-[450px] md:max-w-[500px] lg:max-w-[550px] xl:max-w-[600px] 2xl:max-w-[600px]" style={{ gridArea: "span 1 / span 1 / span 1 / span 1" }}>
                    <motion.div className="w-full uppercase tracking-[0.2rem] text-xs bg-white" style={{ opacity: opacity3, color: colorPreText3 }}>
                        {t("method.boxes.maquette.preTitle")}
                    </motion.div>
                    <motion.h1
                        className="w-full uppercase text-lg text-[#355379] font-medium bg-white "
                        dangerouslySetInnerHTML={{ __html: t("method.boxes.maquette.bigTitle") }}
                        style={{ color: colorTitle3 }}
                    ></motion.h1>
                    <motion.p
                        style={{ color: colorParagraph3, opacity: opacity3 }}
                        className="leading-5 text-xs md:text-[14px] 2xl:text-[16px]"
                        dangerouslySetInnerHTML={{ __html: t("method.boxes.maquette.description") }}
                    ></motion.p>
                </div>
            </div>
            <div
                className="item-4 py-[3.5rem] z-20 flex flex-col grid-col md:grid relative "
                style={{ gridColumnGap: "4rem", gridRowGap: "0rem", gridTemplateColumns: "1fr .01fr 1fr", gridTemplateRows: "auto", gridAutoColumns: "1fr" }}
            >
                <div className="text-left md:text-right sm:ml-[200px] md:ml-0 ml-[60px]" style={{ gridArea: "span 1 / span 1 / span 1 / span 1" }}>
                    <motion.h3
                        className={`will-change-[opacity] text-[30px] md:text-[26px] lg:text-[1.8rem] mt-0 lg:-mt-3 origin-left md:origin-right`}
                        style={{ color: color4, opacity: opacity4, scale: scale4, fontWeight: bold4 }}
                    >
                        {t("method.boxes.dev.title")}
                    </motion.h3>
                </div>
                <div
                    className="self-start w-[1.5rem] h-[1.5rem] pointer-events-none color-[#A1A1A1] justify-center items-stretch flex"
                    style={{ gridArea: "span 1 / span 1 / span 1 / span 1" }}
                >
                    <motion.svg
                        ref={ref4}
                        className="text-[#e2e2e2] w-[1.25rem] absolute top-[28%] bottom-auto left-0 right-[0%] md:right-auto md:left-auto md:top-auto md:bottom-auto md:static md:w-[1.5rem]"
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        viewBox="0 0 24 25"
                        fill="none"
                        style={{ opacity: opacity4, color: color4 }}
                    >
                        <circle cx="12" cy="12.6074" r="10" fill="white" stroke="currentcolor" strokeWidth="4"></circle>
                    </motion.svg>
                </div>
                <div className="ml-[60px] sm:ml-[200px] md:ml-auto sm:max-w-[450px] md:max-w-[500px] lg:max-w-[550px] xl:max-w-[600px] 2xl:max-w-[600px]" style={{ gridArea: "span 1 / span 1 / span 1 / span 1" }}>
                    <motion.div className="w-full uppercase tracking-[0.2rem] text-xs bg-white" style={{ opacity: opacity4, color: colorPreText4 }}>
                        {t("method.boxes.dev.preTitle")}
                    </motion.div>
                    <motion.h1
                        className="w-full uppercase text-lg text-[#355379] font-medium bg-white "
                        dangerouslySetInnerHTML={{ __html: t("method.boxes.dev.bigTitle") }}
                        style={{ color: colorTitle4 }}
                    ></motion.h1>
                    <motion.p
                        style={{ color: colorParagraph4, opacity: opacity4 }}
                        className="leading-5 text-xs md:text-[14px] 2xl:text-[16px]"
                        dangerouslySetInnerHTML={{ __html: t("method.boxes.dev.description") }}
                    ></motion.p>
                </div>
            </div>
            <div
                className="item-4 py-[3.5rem] z-20 flex flex-col grid-col md:grid relative "
                style={{ gridColumnGap: "4rem", gridRowGap: "0rem", gridTemplateColumns: "1fr .01fr 1fr", gridTemplateRows: "auto", gridAutoColumns: "1fr" }}
            >
                <div className="text-left md:text-right sm:ml-[200px] md:ml-0 ml-[60px]" style={{ gridArea: "span 1 / span 1 / span 1 / span 1" }}>
                    <motion.h3
                        className={`will-change-[opacity] text-[30px] md:text-[26px] lg:text-[1.8rem] mt-0 lg:-mt-3 origin-left md:origin-right`}
                        style={{ color: color5, opacity: opacity5, scale: scale5, fontWeight: bold5 }}
                    >
                        {t("method.boxes.deploiment.title")}
                    </motion.h3>
                </div>
                <div
                    className="self-start w-[1.5rem] h-[1.5rem] pointer-events-none color-[#A1A1A1] justify-center items-stretch flex"
                    style={{ gridArea: "span 1 / span 1 / span 1 / span 1" }}
                >
                    <motion.svg
                        ref={ref5}
                        className="text-[#e2e2e2] w-[1.25rem] absolute top-[28%] bottom-auto left-0 right-[0%] md:right-auto md:left-auto md:top-auto md:bottom-auto md:static md:w-[1.5rem]"
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        viewBox="0 0 24 25"
                        fill="none"
                        style={{ opacity: opacity5, color: color5 }}
                    >
                        <circle cx="12" cy="12.6074" r="10" fill="white" stroke="currentcolor" strokeWidth="4"></circle>
                    </motion.svg>
                </div>
                <div className="ml-[60px] sm:ml-[200px] md:ml-auto sm:max-w-[450px] md:max-w-[500px] lg:max-w-[550px] xl:max-w-[600px] 2xl:max-w-[600px]" style={{ gridArea: "span 1 / span 1 / span 1 / span 1" }}>
                    <motion.div className="w-full uppercase tracking-[0.2rem] text-xs bg-white" style={{ opacity: opacity5, color: colorPreText5 }}>
                        {t("method.boxes.deploiment.preTitle")}
                    </motion.div>
                    {/* <motion.h1
                        className="w-full uppercase text-lg text-[#355379] font-medium bg-white "
                        dangerouslySetInnerHTML={{ __html: t("method.boxes.deploiment.bigTitle") }}
                        style={{ color: colorTitle5 }}
                    ></motion.h1> */}
                    <motion.p
                        style={{ color: colorParagraph5, opacity: opacity5 }}
                        className="leading-5 text-xs md:text-[14px] 2xl:text-[16px]"
                        dangerouslySetInnerHTML={{ __html: t("method.boxes.deploiment.description") }}
                    ></motion.p>
                </div>
            </div>
        </div>
    );
};

export default function Method() {
    const { t } = useTranslation();

    return (
        <div id="method" className="min-h-screen flex flex-col items-center mb-8">
            <div className="w-full text-center pt-20 uppercase tracking-[0.2rem] text-primary-100 text-xs md:text-sm bg-white px-4">{t("method.preTitle")}</div>
            <h1
                className="w-full uppercase text-center text-xl md:text-2xl text-[#355379] font-medium bg-white pb-8 px-4"
                dangerouslySetInnerHTML={{ __html: t("method.title") }}
            ></h1>
            <div className="h-full w-full relative px-8">
                <CustomTimeline />
            </div>
            <a href="/work-together" className="cursor-pointer bg-primary-100 text-white px-8 py-2 mt-8 rounded-full hover:opacity-80 transition-all">
                {t("method.button")}
            </a>
        </div>
    );
}
