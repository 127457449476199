import { Tab, TabPanel, Tabs, TabsBody, TabsHeader } from "@material-tailwind/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Navbar from "../../components/Navbar/NavbarWithoutAnimation";
import Footer from "../../components/Footer/Footer";
import Project from "./Project/Project";
import Meeting from "./Meeting/Meeting";
import AmbitiousProject from "../../components/StudyCase/AmbitiousProject";

export default function WorkTogether() {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState("project");

    const widthWindow = window.innerWidth;

    return (
        <div className="w-full bg-white relative">
            <Navbar />
            <div className="absolute z-0 w-full h-72 -mt-12 top-0 left-0  bg-gradient-to-r from-primary-100 to-primary-200 rounded-b-[50px]"></div>
            <div className="relative z-10 w-full flex flex-col px-4 md:px-16 pt-24 mt-12 mb-16 max-w-[1408px] mx-auto">
                <span className="uppercase tracking-[0.2rem] text-white text-xs md:text-sm text-center px-4">{t("workTogether.preTitle")}</span>
                <h1 className="mb-4 uppercase text-white text-xl md:text-2xl text-center px-4">{t("workTogether.title")}</h1>

                <Tabs value="project" className="flex-col gap-4 md:flex-row" orientation={widthWindow < 768 ? "vertical" : "horizontal"}>
                    <div className="px-10">
                    <TabsHeader
                        placeholder=""
                        className="lg:rounded-full text-white uppercase text-sm"
                        indicatorProps={{
                            className: "md:rounded-full font-bold",
                        }}
                    >
                        <Tab
                            onClick={() => setActiveTab("project")}
                            className={`${
                                activeTab === "project" ? "text-black font-semibold" : "text-white"
                            } transition-all  duration-300  ease-in-out font-serif rounded-full text-sm px-2`}
                            placeholder=""
                            value="project"
                        >
                            {t("workTogether.project.title")}
                        </Tab>
                        <Tab
                            onClick={() => setActiveTab("meeting")}
                            className={`${
                                activeTab === "meeting" ? "text-black font-semibold" : "text-white"
                            } transition-all  duration-300  ease-in-out font-serif rounded-full text-sm px-2`}
                            placeholder=""
                            value="meeting"
                        >
                            {t("workTogether.meeting.title")}
                        </Tab>
                    </TabsHeader>
                    </div>
                    <TabsBody placeholder="" className="bg-white w-full rounded-[50px] lg:mt-4">
                        <TabPanel className="pt-4 md:pt-16 px-4 md:px-16 pb-4 md:pb-12 font-serif text-justify text-sm text-black flex flex-col gap-4" value="project">
                            <Project />
                        </TabPanel>
                        <TabPanel className="pt-4 md:pt-16 px-4 md:px-16 pb-4 md:pb-12 font-serif text-justify text-sm text-black flex flex-col gap-4" value="meeting">
                            <Meeting />
                        </TabPanel>
                    </TabsBody>
                </Tabs>
            </div>
            <AmbitiousProject />
            <Footer />
        </div>
    );
}
