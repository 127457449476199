import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import React from 'react';
import ReactDOM from 'react-dom/client';

import "./assets/css/index.css";
import './assets/scss/Header.scss';
import 'swiper/css';
import "./i18n";

import App from "./pages/LandingPage/App";
import WorkTogether from "./pages/WorkTogether/WorkTogether";
import Team from "./pages/Team/Team";

import "react-toastify/dist/ReactToastify.css";
import Lio from "./pages/StudyCases/Lio";
import MentionsLegales from "./pages/Others/MentionsLegales";
import Page404 from "./pages/Others/Page404";
import AsIonisLyon from "./pages/StudyCases/AsIonisLyon";
import Ezoom from "./pages/StudyCases/Ezoom";
import AnnuaireEtudeDeCas from "./pages/StudyCases/AnnuaireEtudeDeCas";
import Yoga from "./pages/StudyCases/Yoga";
import Latoo from "./pages/StudyCases/Latoo";

library.add(fas);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/team" element={<Team />} />
        <Route path="/work-together" element={<WorkTogether />} />
        <Route path="/etudes-de-cas" element={<AnnuaireEtudeDeCas />} />
        <Route path="/etudes-de-cas/*">
          <Route path="lio" element={<Lio />} />
          <Route path="ezoom" element={<Ezoom />} />
          <Route path="as-ionis-lyon" element={<AsIonisLyon />} />
          <Route path="studio-yoga" element={<Yoga />} />
          <Route path="latoo" element={<Latoo />} />

          <Route path="*" element={<Page404 />} />
        </Route>
        <Route path="/mentions-legales" element={<MentionsLegales />} />
        <Route path="/*" element={<Page404 />} />
      </Routes>
      <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
      />
    </BrowserRouter>
  </React.StrictMode>
);