import Header from "../../components/StudyCase/Header";

import HeaderPicture from "../../assets/img/StudyCases/Latoo/headerPicture.jpg"
import HeroPicture from "../../assets/img/StudyCases/Latoo/heroPicture.png"
import LogoPicture from "../../assets/img/StudyCases/Latoo/logoOutline.png"
import CompanyPicture from "../../assets/img/StudyCases/Latoo/companyPicture.png"
import Iteration1Picture from "../../assets/img/StudyCases/Latoo/Iteration1Picture.png"
import Iteration2Picture from "../../assets/img/StudyCases/Latoo/Iteration2Picture.png"
import Iteration3Picture from "../../assets/img/StudyCases/Latoo/Iteration3Picture.png"

import Navbar from "../../components/Navbar/NavbarWithoutAnimation";
import Hero from "../../components/StudyCase/Hero";
import TransitionTransform from "../../components/StudyCase/TransitionTransform";
import DevelopmentCards from "../../components/StudyCase/DevelopmentCards";
import AmbitiousProject from "../../components/StudyCase/AmbitiousProject";
import Footer from "../../components/Footer/Footer";
import Challenge from "../../components/StudyCase/Challenge";

export default function Latoo() {
    return (
        <div>
            <Navbar />
            <Header 
                headerPicture={HeaderPicture}
                logoPicture={LogoPicture}
            />
            <Hero
                pictureLeft={HeroPicture}
                nameForTranslation="latoo"
            />
            <TransitionTransform />
            <DevelopmentCards
                iteration={-1}
                nameForTranslation="latoo"
                picture={CompanyPicture}
                contentSide="right"
                nbrPoints={0}
            />
            <Challenge
                nameForTranslation="latoo"
            />
            <DevelopmentCards
                iteration={1}
                nameForTranslation="latoo"
                picture={Iteration1Picture}
                contentSide="left"
                nbrPoints={4}
            />
            <DevelopmentCards
                iteration={2}
                nameForTranslation="latoo"
                picture={Iteration2Picture}
                contentSide="right"
                nbrPoints={3}
            />
            <DevelopmentCards
                iteration={3}
                nameForTranslation="latoo"
                picture={Iteration3Picture}
                contentSide="left"
                nbrPoints={3}
            />
            <AmbitiousProject />
            <Footer />
        </div>
    )
}