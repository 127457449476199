import { useTranslation } from "react-i18next";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/NavbarWithoutAnimation";

export default function MentionsLegales() {
    const { t } = useTranslation();

    return (
        <div className="w-full bg-gradient-to-br from-primary-100 to-primary-200">
            <Navbar />
            <div className="w-full min-h-screen flex flex-col px-4 md:px-16 pt-24 mb-12 max-w-[1280px] mx-auto">
                <span className="uppercase tracking-[0.2rem] text-white text-xs md:text-sm text-center px-4">{t("mentionsLegales.preTitle")}</span>
                <h1 className=" uppercase text-white text-xl text-center px-4">{t("mentionsLegales.title")}</h1>

                <div className="w-full flex flex-col gap-6 bg-white rounded-lg lg:mt-4 pt-4 md:py-8 px-4 md:px-16 pb-4 font-serif text-justify text-sm text-black">
                    <div className="flex flex-col gap-3">
                        <div className="relative z-10 w-fit text-left uppercase tracking-[0.2rem] text-primary-100 text-xs md:text-sm bg-white">
                            {t("mentionsLegales.identity.title")}
                        </div>
                        <div className="flex flex-col gap-1">
                            <h1 className="w-fit uppercase text-xl text-[#355379] font-medium bg-white">
                                {t("mentionsLegales.identity.denominationSociale")}
                            </h1>
                            <div className="leading-6 text-xs md:text-[14px] 2xl:text-[16px] text-justify">
                                {t("mentionsLegales.identity.denominationSociale_d")}
                            </div>
                        </div>
                        <div className="flex flex-col gap-1">
                            <h1 className="w-fit uppercase text-xl text-[#355379] font-medium bg-white">
                                {t("mentionsLegales.identity.formeJuridique")}
                            </h1>
                            <div className="leading-6 text-xs md:text-[14px] 2xl:text-[16px] text-justify">
                                {t("mentionsLegales.identity.formeJuridique_d")}
                            </div>
                        </div>
                        <div className="flex flex-col gap-1">
                            <h1 className="w-fit uppercase text-xl text-[#355379] font-medium bg-white">
                                {t("mentionsLegales.identity.capitalSocial")}
                            </h1>
                            <div className="leading-6 text-xs md:text-[14px] 2xl:text-[16px] text-justify">{t("mentionsLegales.identity.capitalSocial_d")}</div>
                        </div>
                    </div>
                    <hr />
                    <div className="flex flex-col gap-3">
                        <div className="relative z-10 w-fit text-left uppercase tracking-[0.2rem] text-primary-100 text-xs md:text-sm bg-white">
                            {t("mentionsLegales.coordonnees.title")}
                        </div>
                        <div className="flex flex-col gap-1">
                            <h1 className="w-fit uppercase text-xl text-[#355379] font-medium bg-white">
                                {t("mentionsLegales.coordonnees.adresseSiegeSocial")}
                            </h1>
                            <div className="leading-6 text-xs md:text-[14px] 2xl:text-[16px] text-justify">
                                {t("mentionsLegales.coordonnees.adresseSiegeSocial_d")}
                            </div>
                        </div>
                        <div className="flex flex-col gap-1">
                            <h1 className="w-fit uppercase text-xl text-[#355379] font-medium bg-white">
                                {t("mentionsLegales.coordonnees.adresseDeCourrier")}
                            </h1>
                            <div className="leading-6 text-xs md:text-[14px] 2xl:text-[16px] text-justify">
                                {t("mentionsLegales.coordonnees.adresseDeCourrier_d")}
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="flex flex-col gap-3">
                        <div className="relative z-10 w-fit text-left uppercase tracking-[0.2rem] text-primary-100 text-xs md:text-sm bg-white">
                            {t("mentionsLegales.proprieteIntellectuelle.title")}
                        </div>
                        <div className="flex flex-col gap-1">
                            <div className="leading-6 text-xs md:text-[14px] 2xl:text-[16px] text-justify">
                                {t("mentionsLegales.proprieteIntellectuelle.textes")}
                            </div>
                            <div
                                className="leading-6 text-xs md:text-[14px] 2xl:text-[16px] text-justify"
                                dangerouslySetInnerHTML={{ __html: t("mentionsLegales.proprieteIntellectuelle.images") }}
                            ></div>
                        </div>
                    </div>
                    <hr />
                    <div className="flex flex-col gap-3">
                        <div className="relative z-10 w-fit text-left uppercase tracking-[0.2rem] text-primary-100 text-xs md:text-sm bg-white">
                            {t("mentionsLegales.hebergement.title")}
                        </div>
                        <div className="flex flex-col gap-1">
                            <h1 className="w-fit uppercase text-xl text-[#355379] font-medium bg-white">
                                {t("mentionsLegales.hebergement.hebergeur")}
                            </h1>
                            <div className="leading-6 text-xs md:text-[14px] 2xl:text-[16px] text-justify">
                                {t("mentionsLegales.hebergement.hebergeur_d")}
                            </div>
                        </div>
                        <div className="flex flex-col gap-1">
                            <h1 className="w-fit uppercase text-xl text-[#355379] font-medium bg-white">
                                {t("mentionsLegales.hebergement.raisonSociale")}
                            </h1>
                            <div className="leading-6 text-xs md:text-[14px] 2xl:text-[16px] text-justify">
                                {t("mentionsLegales.hebergement.raisonSociale_d")}
                            </div>
                        </div>
                        <div className="flex flex-col gap-1">
                            <h1 className="w-fit uppercase text-xl text-[#355379] font-medium bg-white">
                                {t("mentionsLegales.hebergement.adresseHebergeur")}
                            </h1>
                            <div className="leading-6 text-xs md:text-[14px] 2xl:text-[16px] text-justify">
                                {t("mentionsLegales.hebergement.adresseHebergeur_d")}
                            </div>
                        </div>
                        <div className="flex flex-col gap-1">
                            <h1 className="w-fit uppercase text-xl text-[#355379] font-medium bg-white">
                                {t("mentionsLegales.hebergement.telephoneHebergeur")}
                            </h1>
                            <div className="leading-6 text-xs md:text-[14px] 2xl:text-[16px] text-justify">
                                {t("mentionsLegales.hebergement.telephoneHebergeur_d")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
