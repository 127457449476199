import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input, Textarea } from "@material-tailwind/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const urlForForm = process.env.REACT_APP_FORM_URL;

export default function Project() {
    const { t } = useTranslation();
    const [boxes, setBoxes] = useState<string | null>(localStorage.getItem("selectedBoxes") ?? "[]" as string);
    const [datas, setDatas] = useState<{
        firstname: string,
        lastname: string,
        email: string,
        phone: string,
        company: string,
        profession: string,
        message: string
    }>({
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        company: "",
        profession: "",
        message: ""
    })

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!urlForForm) return;

        let newPhone = datas.phone;

        if (newPhone.startsWith("0")) {
            newPhone = newPhone.slice(1);
            newPhone = `+33${newPhone}`;
            console.log(newPhone)
        }

        const data = {
            firstname: datas.firstname,
            lastname: datas.lastname,
            mail: datas.email,
            phone: newPhone,
            company: datas.company,
            profession: datas.profession,
            message: datas.message,
            ...(boxes && boxes.length > 0 && { fonctionnalities: JSON.parse(boxes) })
        }

        try {
            const response = await fetch(urlForForm, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            }).then(res => res.json());

            if (response.error) {
                toast.error(t("workTogether.project.error"));
            } else {
                toast.success(t("workTogether.project.success"));
            }
        } catch (error) {
            toast.error(t("workTogether.project.error"));
        }
    }

    const handleDeleteBox = (item: string) => {
        if (!boxes) return;
        const newBoxes = JSON.parse(boxes).filter((box: string) => box !== item);
        localStorage.setItem("selectedBoxes", JSON.stringify(newBoxes));
        setBoxes(JSON.stringify(newBoxes));
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setDatas({
            ...datas,
            [e.target.name]: e.target.value
        })
    }

    return (
        <div className="flex flex-col gap-4">
            {boxes && JSON.parse(boxes).length > 0 && (
                <>
                    <div className="bg-primary-100/20 rounded-lg px-4 py-2">
                        <h2 className="text-primary-100 text-base mb-2 font-medium">{t("workTogether.project.selected")}</h2>
                        <div className="flex flex-wrap gap-2">
                            {JSON.parse(boxes).map((item: string) => (
                                <div key={item} className="text-primary-100 bg-white px-2 py-1 rounded-full text-xs">{item} <FontAwesomeIcon icon="x" className="text-[10px] ml-2 cursor-pointer" onClick={() => handleDeleteBox(item)}/></div>
                            ))}
                        </div>
                    </div>
                    <hr className="md:hidden" />
               </>
            )}
            <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
                <div className="flex flex-col md:flex-row gap-4">
                    <Input
                        crossOrigin=""
                        type="text"
                        color="blue"
                        label={t("workTogether.project.form.firstname")}
                        className="font-serif"
                        name="firstname"
                        value={datas.firstname}
                        onChange={handleChange}
                        required
                    />
                    <Input
                        crossOrigin=""
                        type="text"
                        color="blue"
                        label={t("workTogether.project.form.lastname")}
                        className="font-serif"
                        name="lastname"
                        value={datas.lastname}
                        onChange={handleChange}
                        required
                    />
                </div>
                <hr className="md:hidden" />
                <div className="flex flex-col md:flex-row gap-4">
                    <Input
                        crossOrigin=""
                        type="text"
                        color="blue"
                        label={t("workTogether.project.form.email")}
                        className="font-serif"
                        name="email"
                        value={datas.email}
                        onChange={handleChange}
                        required
                    />
                    <Input
                        crossOrigin=""
                        type="text"
                        color="blue"
                        label={t("workTogether.project.form.phone")}
                        className="font-serif"
                        name="phone"
                        value={datas.phone}
                        onChange={handleChange}
                        required
                    />
                </div>
                <hr className="md:hidden" />
                <div className="flex flex-col md:flex-row gap-4">
                    <Input
                        crossOrigin=""
                        type="text"
                        color="blue"
                        label={t("workTogether.project.form.company")}
                        className="font-serif"
                        name="company"
                        value={datas.company}
                        onChange={handleChange}
                        required
                    />
                    <Input
                        crossOrigin=""
                        type="text"
                        color="blue"
                        label={t("workTogether.project.form.profession")}
                        className="font-serif"
                        name="profession"
                        value={datas.profession}
                        onChange={handleChange}
                        required
                    />
                </div>
                <hr className="md:hidden" />
                <Textarea
                    color="blue"
                    label={t("workTogether.project.form.message")}
                    className="font-serif"
                    name="message"
                    value={datas.message}
                    onChange={handleChange}
                    required
                />
                <button className="bg-primary-100 hover:bg-primary-100/80 transition-all text-white font-serif text-sm py-2 px-8 rounded-full w-fit mx-auto">
                    {t("workTogether.project.button")}
                </button>
            </form>
        </div>
    )
}