import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion, useScroll, useTransform } from "framer-motion";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import FirstPicture from "../../../assets/img/FirstSection.png";

export default function Headers() {
    const { t } = useTranslation();
    const isCpuRenderedBrowser = ["mozilla"].some((browser) => navigator.userAgent.toLowerCase().includes(browser));
    const bluring = isCpuRenderedBrowser ? { filter: "blur(100px)" } : {};

    const refInterBubble = useRef<HTMLDivElement>(null);
    const containBackground = useRef<HTMLDivElement>(null);
    const divRef = useRef<HTMLDivElement>(null);
    const scalePictureRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const interBubble = refInterBubble.current!;
        if (!interBubble) return;
        let curX = 0;
        let curY = 0;
        let tgX = 0;
        let tgY = 0;

        function move() {
            curX += (tgX - curX) / 20;
            curY += (tgY - curY) / 20;
            interBubble.style.transform = `translate(${Math.round(curX)}px, ${Math.round(curY)}px)`;
            requestAnimationFrame(() => {
                move();
            });
        }

        window.addEventListener("mousemove", (event) => {
            tgX = event.clientX;
            tgY = event.clientY;
        });

        move();
    }, []);

    const halfPageHeight = window.innerHeight / 2;
    const { scrollY } = useScroll();
    const scale = useTransform(scrollY, [0, halfPageHeight], [1, 0.95]);
    const borderRadius = useTransform(scrollY, [0, halfPageHeight], [0, window.innerWidth < 400 ? 35 : 50]);
    const elementOpacity = useTransform(scrollY, [0, 100], [1, 0]);
    const elementDisplay = useTransform(scrollY, [0, 100, 101], ["block", "block", "none"]);
    useEffect(() => {
        console.log(elementDisplay.get()); // 2, input clamped
    });
    return (
        <div ref={divRef} className="relative w-full h-[130vh] bg-night-900">
            <div ref={containBackground} className="h-screen w-full sticky top-0">
                <motion.div style={{ scale, borderRadius }} ref={scalePictureRef} className="h-full w-full relative overflow-hidden gradient-bg">
                    <svg xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <filter id="goo">
                                <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -8" result="goo" />
                                <feBlend in="SourceGraphic" in2="goo" />
                            </filter>
                        </defs>
                    </svg>
                    <motion.div className="gradients-container" style={{ display: elementDisplay, opacity: elementOpacity }}>
                        <div className="g1"></div>
                        <div className="g2"></div>
                        <div className="g3"></div>
                        <div className="g4"></div>
                        <div className="g5"></div>
                        <div className="interactive" ref={refInterBubble} style={bluring}></div>
                    </motion.div>
                    <div className="absolute top-1/2 -translate-y-1/2 left-0 right-0 justify-start items-start flex flex-col gap-4 z-10 w-full">
                        <div className="flex flex-col w-full md:w-2/3 2xl:w-1/2 gap-4 px-6 md:px-16">
                        {/* <div className="flex flex-col w-full max-w-[1680px] gap-4 px-6 md:px-16"> */}
                            <span className="uppercase tracking-[0.2rem] text-white text-sm">{t("header.preTitle")}</span>
                            <span className="hidden 2xl:block uppercase font-bold text-white text-4xl" dangerouslySetInnerHTML={{ __html: t("header.title") }}></span>
                            <span className="block 2xl:hidden uppercase font-bold text-white text-4xl" dangerouslySetInnerHTML={{ __html: t("header.title_small") }}></span>
                            <span className="text-white text-lg" dangerouslySetInnerHTML={{ __html: t("header.subtitle") }}></span>
                            <a href="/work-together" className="cursor-pointer px-5 py-3 rounded-full bg-white/40 hover:bg-white/30 transition-all backdrop-blur-lg text-white w-fit text-base relative z-50">
                                {t("header.button")}
                                <FontAwesomeIcon icon="arrow-right" />
                            </a>
                        </div>
                    </div>
                    <div className="absolute -bottom-16 -right-80 hidden md:block">
                        <img src={FirstPicture} className="max-h-[500px] xl:max-h-[625px] 2xl:max-h-[850px]" />
                    </div>
                </motion.div>
            </div>
        </div>
    );
}
