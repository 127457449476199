import { useTranslation } from "react-i18next";

import topCorner from "../../assets/img/decorations/topLeftCornerTransparent.png";
import bottomCorner from "../../assets/img/decorations/bottomRightCornerTransparent.png";

interface OpinionProps {
    nameForTranslation: string;
}

export default function Opinion({ nameForTranslation }: OpinionProps) {
    const { t } = useTranslation();

    return (
        <div className="w-full flex items-center justify-center py-12 bg-primary-100/20 h-full px-4">
            <div className="flex flex-col justify-center items-center gap-1 max-w-[1280px] w-full h-full relative px-4 md:px-12 bg-[#f7f7fa] rounded-2xl py-8">
                <div className="leading-7 text-xs md:text-[14px] text-justify" dangerouslySetInnerHTML={{ __html: t(`studyCase.${nameForTranslation}.opinion`)}}>
                </div>
                <div className="w-full text-xs md:text-[14px] text-primary-100 font-medium md:text-right uppercase">
                    {t(`studyCase.commun.opinion`)}
                </div>
                
                <div className="z-0 absolute -left-2 -top-2">
                    <img src={topCorner} alt="top corner" className="w-12 sm:w-16 opacity-50" />
                </div>
                <div className="z-0 absolute -right-2 -bottom-2">
                    <img src={bottomCorner} alt="top corner" className="w-12 sm:w-16 opacity-50" />
                </div>
            </div>
        </div>
    )
}