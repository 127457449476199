import { useTranslation } from "react-i18next";
import LineDecoration from "../../../assets/img/decorations/borderBottomSmaller.png"
import CapellePicture from "../../../assets/img/Team/Quentin.png"
import MathiasPicture from "../../../assets/img/Team/Mathias.png"
import { Link } from "react-scroll";

export default function Audit() {
    const { t } = useTranslation();

    return (
        <div className="bg-gray-50 w-full h-full my-12">
            <div className="max-w-[1280px] py-12 px-4 md:px-12 gap-8 md:gap-4 flex flex-col lg:flex-row justify-center items-center w-full  mx-auto">
                <div className="w-full flex flex-col">
                    <span className="uppercase tracking-[0.2rem] text-primary-100 text-xs md:text-sm w-full">{t("experts.preTitle")}</span>
                    <h1 className="uppercase text-xl md:text-2xl text-[#355379] font-medium w-full" dangerouslySetInnerHTML={{ __html: t("experts.title") }}></h1>
                    <img src={LineDecoration} alt="Line Decoration" className="w-2/3 mt-4 opacity-50" />
                </div>
                <div className="w-full flex flex-col justify-start items-center gap-8">
                    <div className="flex flex-col md:flex-row justify-center items-center gap-4">
                        <img src={CapellePicture} alt="Quentin Capelle" className="w-28 h-28 lg:w-40 lg:h-40 rounded-full" />
                        <div className="flex flex-col justify-center items-center md:items-start">
                            <span className="text-base text-[#656361] text-center md:text-left" dangerouslySetInnerHTML={{ __html: t("experts.capelle.describe") }}></span>
                            <a href="/work-together" className="decoration-primary-100 text-base text-primary-100 underline" dangerouslySetInnerHTML={{ __html: t("experts.capelle.button") }}></a>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row justify-center items-center gap-4">
                        <img src={MathiasPicture} alt="Mathias André" className="w-28 h-28 lg:w-40 lg:h-40 rounded-full" />
                        <div className="flex flex-col justify-center items-center md:items-start">
                            <span className="text-base text-[#656361] text-center md:text-left" dangerouslySetInnerHTML={{ __html: t("experts.mathias.describe") }}></span>
                            <a href="mailto:mathias.andre@capeima.fr" className="decoration-primary-100 text-base text-primary-100 underline" dangerouslySetInnerHTML={{ __html: t("experts.mathias.button") }}></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}