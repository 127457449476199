import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Drawer, IconButton, Popover, PopoverContent, PopoverHandler, Typography } from "@material-tailwind/react";
import { useMotionValue, useTransform, motion, useScroll } from "framer-motion";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";

export default function Navbar() {
    const { t } = useTranslation();
    const [navBarDark, setNavBarDark] = useState(false);
    const [open, setOpen] = useState(false);
    const [studyCaseOpen, setStudyCaseOpen] = useState(false);

    const openDrawer = () => {
        if (open) return;
        setOpen(true);
    };

    const closeDrawer = () => {
        if (!open) return;
        setOpen(false);
    };

    useEffect(() => {
        const handleScroll = () => {
            scrollY.set(window.scrollY);
            if (window.scrollY >= window.innerHeight * 1.15) {
                setNavBarDark(true);
            } else {
                setNavBarDark(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const halfPageHeight = window.innerHeight / 2;
    const { scrollY } = useScroll();
    const scale = useTransform(scrollY, [0, halfPageHeight], [1, 0.95]);
    const marginTop = useTransform(scrollY, [0, halfPageHeight], [0, -3]);
    const opacity = useTransform(scrollY, [0, window.innerHeight * 1.15 - 400, window.innerHeight * 1.15 - 200], [0, 0, 1]);

    return (
        <>
            <motion.div
                className="w-full flex justify-center items-center fixed top-0 left-0 z-40"
                style={{
                    scale: scale,
                    marginTop: marginTop,
                }}
            >
                <div className={`relative transition-all duration-500 ease-in-out rounded-b-[25px] md:rounded-b-[50px] w-full bg-white`}>
                    <motion.div
                        className={`bg-gradient-to-r from-primary-200 to-[#0168A9] absolute w-full h-full transition-all duration-500 ease-in-out rounded-b-[25px] md:rounded-b-[50px] z-10`}
                        style={{
                            width: "100%",
                            height: "59px",
                            opacity: opacity,
                        }}
                    />
                    <div className="py-7 px-8 absolute top-0 left-0 bg-transparent w-full h-full z-20 flex gap-4 md:gap-0 md:justify-between items-center">
                        <div className="flex md:hidden">
                            <button onClick={openDrawer} disabled={open} className="text-white text-base cursor-pointer">
                                <FontAwesomeIcon icon="bars" />
                            </button>
                        </div>
                        <div className="text-white text-base md:text-2xl">
                            <span className="uppercase font-sans">{t("brand.name")}</span>
                        </div>
                        <div className="hidden md:flex flex-row gap-4 items-center">
                            {/* <Link to="solution" smooth={true}>
                                <div className="text-white text-base cursor-pointer">
                                    <span className="hidden md:flex">{t("navbar.services")}</span>
                                </div>
                            </Link> */}
                            {/* <Link to="references" smooth={true}>
                                <div className="text-white text-base cursor-pointer">
                                    <span className="hidden md:flex">{t("navbar.references")}</span>
                                    <FontAwesomeIcon icon="stamp" className="flex md:hidden" />
                                </div>
                            </Link> */}
                            <Link to="method" smooth={true}>
                                <div className="text-white text-base cursor-pointer">
                                    <span className="hidden md:flex">{t("navbar.methods")}</span>
                                </div>
                            </Link>
                            <a href="/team">
                                <div className="text-white text-base cursor-pointer">
                                    <span className="hidden md:flex">{t("navbar.team")}</span>
                                </div>
                            </a>
                            <a href="/etudes-de-cas">
                                <div className="text-white text-base cursor-pointer">
                                    <span className="hidden md:flex">{t("navbar.studyCase")}</span>
                                </div>
                            </a>
                            {/* <Popover placement="bottom-start">
                                <PopoverHandler>
                                    <button className={`text-white transition-all text-base cursor-pointer pr-2`}>
                                        <span className="hidden md:flex">{t("navbar.studyCase")}</span>
                                    </button>
                                </PopoverHandler>
                                <PopoverContent placeholder="" className="z-40 font-serif flex flex-col">
                                    <a href="/etudes-de-cas/lio">
                                        <div className="text-primary-200 hover:opacity-80 transition-all text-sm cursor-pointer">
                                            <span className="flex">{t("navbar.studyCases.lio")}</span>
                                        </div>
                                    </a>
                                </PopoverContent>
                            </Popover> */}
                            <a href="/work-together">
                                <div className="text-white text-base bg-white/30 hover:bg-white/20 transition-all px-4 py-2 rounded-full cursor-pointer">
                                    <span className="hidden md:flex">{t("navbar.estimateYourProject")}</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </motion.div>

            <Drawer overlay={false} placeholder="" open={open} onClose={closeDrawer} className="p-4">
                <div className="mb-6 flex items-center justify-between">
                    <Typography placeholder="" variant="h5" className="text-primary-100 uppercase">
                        Capeima
                    </Typography>
                    <IconButton placeholder="" variant="text" className="text-primary-100" onClick={closeDrawer}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="h-5 w-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </IconButton>
                </div>
                <div className="flex flex-col gap-4">
                    {/* <Link to="solution" smooth={true}>
                        <div className="text-[#355379] hover:opacity-80 transition-all text-base cursor-pointer">
                            <span className="flex">{t("navbar.services")}</span>
                        </div>
                    </Link> */}
                    {/* <Link to="references" smooth={true}>
                        <div className="text-[#355379] hover:opacity-80 transition-all text-base cursor-pointer">
                            <span className="flex">{t("navbar.references")}</span>
                        </div>
                    </Link> */}
                    <Link to="method" smooth={true}>
                        <div className="text-[#355379] hover:opacity-80 transition-all text-base cursor-pointer">
                            <span className="flex">{t("navbar.methods")}</span>
                        </div>
                    </Link>
                    <a href="/team">
                        <div className="text-[#355379] hover:opacity-80 transition-all text-base cursor-pointer">
                            <span className="flex">{t("navbar.team")}</span>
                        </div>
                    </a>
                    <a href="/etudes-de-cas">
                        <div className="text-[#355379] hover:opacity-80 transition-all text-base cursor-pointer">
                            <span className="flex">{t("navbar.studyCase")}</span>
                        </div>
                    </a>
                    <a href="/work-together" >
                        <div className="text-white text-base bg-primary-200 hover:bg-primary-200/80 transition-all px-4 py-2 rounded-full cursor-pointer w-fit mx-auto">
                            <span className="flex">{t("navbar.estimateYourProject")}</span>
                        </div>
                    </a>
                </div>
            </Drawer>
        </>
    );
}
